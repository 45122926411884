<template>
    <footer v-if="selectedHeliCounter > 0" class="dashboard-footer" :style="{ background: linearGradientStyle }">
      <section :style="{ background: linearGradientStyle }">
        <div class="footerContainer">
          <!-- Buttons for each selected helicopter -->
          <div class="selectionContainer">
            <!-- Navigation Button for Previous -->
            <div v-if="showPreviousButton" class="navButton">
              <button class="btn hollow block" @click="showPrevious">
                <
              </button>
            </div>
            <template v-for="heli in visibleHelicopters">
              <div class="buttonHeader">
                <button class="btn hollow block">{{ heli.serial }}</button>
              </div>
            </template>
            <!-- Navigation Button for Next -->
            <div v-if="showNextButton" class="navButton">
              <button class="btn hollow block" @click="showNext">
                >
              </button>
            </div>
          </div>
  
          <div class="buttonFooter">
            <template class="rightButtonBlock">
              <div class="buttonHeader">
                <button v-if="isAHAdmin && selectedHeliCounter == 1 && !getSelectedDetailed[0].banned" class="btn hollow block" @click="activateBannedModal()">
                  {{ $t('global.button_ban_hc') }}
                </button>
              </div>
  
              <div class="buttonHeader">
                <button v-if="isAHAdmin && selectedHeliCounter == 1 && getSelectedDetailed[0].banned" class="btn hollow block" @click="activateUnbannedModal()">
                  {{ $t('global.button_unban_hc') }}
                </button>
              </div>
  
              <div class="buttonHeader">
                <button v-if="isAHAdmin" class="btn hollow block" @click="activateRemoveHCModal()">
                  {{ $t('global.button_remove_hc') }}
                </button>
              </div>
  
              <div class="buttonHeader">
                <button class="btn hollow block" @click="activateRetrieveModal()">
                  {{ $t('global.button_download_data') }}
                </button>
              </div>
  
              <div class="buttonHeader">
                <button v-if="isAdmin" class="btn hollow block" @click="activateUploadModal()">
                  {{ $t('global.button_upload_data') }}
                </button>
              </div>
            </template>
          </div>
        </div>
  
        <modal :title="uploadFilesModal.title" :modalName="uploadFilesModal.name" width="774px"  height="550px">
          <ModalUploadFiles @responseSuccess="modalResponseSuccess" @responseError="modalResponseError" />
        </modal>
        <modal :title="retrieveMultiModal.title" :modalName="retrieveMultiModal.name" width="774px" height="550px">
          <ModalRetrieveMulti @responseSuccess="modalResponseSuccess" @responseError="modalResponseError" />
        </modal>
        <modal :title="removeHCModal.title" :modalName="removeHCModal.name" width="460px">
          <ModalRemoveHC @responseSuccess="modalResponseSuccess" @responseError="modalResponseError" />
        </modal>
        <modal :title="banHCModal.title" :modalName="banHCModal.name" width="460px">
          <ModalBanHC :getSelectedDetailed="banHCModal.props" @responseSuccess="modalResponseSuccess" @responseError="modalResponseError" />
        </modal>
        <modal :title="unbanHCModal.title" :modalName="unbanHCModal.name" width="460px">
          <ModalUnbanHC :getSelectedDetailed="unbanHCModal.props" @responseSuccess="modalResponseSuccess" @responseError="modalResponseError" />
        </modal>
        <modal :modalName="confirmModal.name" width="430px">
          <ModalConfirm :message="confirmModal.message" :error="confirmModal.error" @modalConfirmed="closeModal()" />
        </modal>
      </section>
    </footer>
  </template>
  
  <script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import bus from '../event/bus'
  import Modal from '../components/modals/Modal'
  import ModalUploadFiles from './modals/ModalUploadFiles'
  import ModalConfirm from './modals/ModalConfirm'
  import ModalRetrieveMulti from './modals/ModalRetrieveMulti'
  import ModalRemoveHC from './modals/ModalRemoveHC'
  import ModalBanHC from './modals/ModalBanHC'
  import ModalUnbanHC from './modals/ModalUnbanHC'
  
  export default {
    name: 'DashboardFooter',
    components: {
      Modal,
      ModalUploadFiles,
      ModalConfirm,
      ModalRetrieveMulti,
      ModalRemoveHC,
      ModalBanHC,
      ModalUnbanHC
    },
    data() {
      return {
        filterData: '',
        placeholder: 'global.search_placeholder',
        uploadFilesModal: {
          name: 'ModalUploadFiles',
          title: 'uploadModal.title'
        },
        retrieveMultiModal: {
          name: 'ModalRetrieveMulti',
          title: 'retrieveMultiModal.title'
        },
        removeHCModal: {
          name: 'ModalRemoveHC',
          title: 'removeHCModal.title'
        },
        banHCModal: {
          name: 'ModalBanHC',
          title: 'banHCModal.title'
        },
        unbanHCModal: {
          name: 'ModalUnbanHC',
          title: 'unbanHCModal.title'
        },
        confirmModal: {
          name: 'confirmRetriveMulti',
          message: '',
          error: false
        },
        currentIndex: 0,
        itemsPerPage: 6
      }
    },
    computed: {
      ...mapState(['userStore']),
      ...mapGetters('fleetStore', [
        'areAllFleetAdded',
        'isAnyActiveSelection',
        'selectedHeliCounter',
        'getSelectedDetailed'
      ]),
      ...mapGetters('userStore', ['isAdmin', 'isAHAdmin']),
      linearGradientStyle() {
        const startColor = window.env.VUE_APP_BACKGROUND_1
        const endColor = window.env.VUE_APP_BACKGROUND_2
        return `linear-gradient(to left, ${startColor}, ${endColor})`
      },
      visibleHelicopters() {
        const start = this.currentIndex;
        const end = start + this.itemsPerPage;
        return this.getSelectedDetailed.slice(start, end);
      },
      showPreviousButton() {
        return this.currentIndex > 0;
      },
      showNextButton() {
        return this.currentIndex + this.itemsPerPage < this.selectedHeliCounter;
      }
    },
    watch: {
      selectedHeliCounter(newVal) {
        if (newVal < this.itemsPerPage) {
          this.currentIndex = 0;
        }
      }
    },
    methods: {
      ...mapActions('fleetStore', ['setFleetQuery', 'setFetchedFleet']),
      ...mapActions('appStore', ['openModal', 'closeModal']),
  
      submitFilter() {
        bus.$emit('submitFilter', { state: true });
        this.setFleetQuery(`?query=${this.filterData}`);
        this.$requester
          .get(`/helicopters?query=${this.filterData}`, {
            filter: this.filterData
          })
          .then(response => {
            try {
              const parsedData = JSON.parse(response.data);
              if (parsedData && parsedData.fleets) {
                this.setFetchedFleet(parsedData.fleets);
              } else {
                console.error('La réponse JSON ne contient pas la clé "fleets".');
              }
            } catch (error) {
              console.error('Erreur lors de l\'analyse de la réponse JSON:', error);
            }
          })
          .catch(error => {
            console.log("fetch fleet error", error);
          });
      },
      activateAddHCModal() {
        this.openModal(this.addHCModal.name);
      },
      activateUploadModal() {
        let ifLessThanOneHcType = true;
        let selectedHelicopers = this.getSelectedDetailed.map(h => ({
          registration: h.registration,
          serial: h.serial,
          type: h.type
        }));
        if (this.selectedHeliCounter > 1) {
          let hcTypeValueToVerify = selectedHelicopers[0].type;
          for (let i = 0; i < this.selectedHeliCounter; i++) {
            if (selectedHelicopers[i].type !== hcTypeValueToVerify) {
              ifLessThanOneHcType = false;
            }
          }
        }
        if (ifLessThanOneHcType) {
          this.openModal(this.uploadFilesModal.name);
        } else {
          let msg = this.$t('global.upload_modal_error');
          this.modalResponseError(msg);
        }
      },
      activateBannedModal() {
        this.openModal(this.banHCModal.name);
      },
      activateUnbannedModal() {
        this.openModal(this.unbanHCModal.name);
      },
      activateRetrieveModal() {
        this.openModal(this.retrieveMultiModal.name);
      },
      activateRemoveHCModal() {
        this.openModal(this.removeHCModal.name);
      },
      modalResponseSuccess(msg) {
        this.confirmModal.error = false;
        this.confirmModal.message = msg || this.confirmModal.message;
        this.closeModal();
        this.openModal(this.confirmModal.name);
      },
      modalResponseError(errorMsg) {
        this.confirmModal.error = true;
        this.confirmModal.message = errorMsg;
        this.closeModal();
        this.openModal(this.confirmModal.name);
      },
      showPrevious() {
        if (this.currentIndex > 0) {
          this.currentIndex -= this.itemsPerPage;
        }
      },
      showNext() {
        if (this.currentIndex + this.itemsPerPage < this.selectedHeliCounter) {
          this.currentIndex += this.itemsPerPage;
        }
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .dashboard-footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - 190px);
    z-index: 1000; /* Ensure it appears above other content */
  }
  
  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .selectionContainer {
    display: flex;
    align-items: center;
    height: 50px;
    flex-grow: 1; /* Allow this container to grow and take available space */
  }
  
  .buttonFooter {
    display: flex;
    align-items: center;
    height: 50px;
    flex-grow: 0; /* Ensure this container does not grow and stays to the right */
  }
  
  .navButton {
    display: flex;
    align-items: center;
    height: 50px;
  }
  </style>