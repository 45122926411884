<template>
  <div class="general-view dashboard">
    <div class="fixed-bar">
      <DashboardHeader/>
      <DashboardSubHeader />
  <DashboardFooter />

    </div>

    <PageLoading v-if="requsterProcesing"  />

    <div v-else>
      <section v-if="!errorOccurred" class="content-wrap">
        <ul>
          <li :data-company="fleet.name" v-for="(fleet, index) in fleetList" :key="fleet.id">
            <Fleet :fleet="fleet" :isFirstIteration="index === 0" />
          </li>

        </ul>


      </section>
      </div>
  <!--
    <modal :modalName="confirmModal.name" width="460px">
      <ModalConfirm :message="confirmModal.message" :error="confirmModal.error" :warn="true" @modalConfirmed="closeModal()" />
    </modal>
    -->
    </div>
</template>


<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavLayout from '@/layouts/NavLayout'

import DashboardHeader from '../components/DashboardHeader'
import DashboardSubHeader from '../components/DashboardSubHeader'
import Fleet from '../components/Fleet'
import PageLoading from './PageLoading'
import Modal from '../components/modals/Modal'
import ModalConfirm from '../components/modals/ModalConfirm'
import axios from 'axios';
import bus from '../event/bus';
import DashboardFooter from '../components/DashboardFooter'

export default {
  components: {
    DashboardHeader,
    DashboardSubHeader,
    Fleet,
    PageLoading,
    Modal,
    ModalConfirm,
    DashboardFooter 
},
  data() {
    return {
      fetchingFleetsTimeoutId: null,
      requsterProcesing: false,
      errorOccurred: false,
      //searchProcesing: false,
      cancelTokenSource: null,
      previousCancelToken: null,
      errorOccurred: false,
    /*confirmModal: {
        name: 'errorModal',
        message: '',
        error: false
      },*/
    }
  },
  
  computed: {
    ...mapState('fleetStore', ['fleetList', 'fleetQuery']),
    ...mapGetters('userStore', ['canFilter']),
  },
  created() {
    this.$emit('update:layoutComponent', NavLayout)
    this.requsterProcesing = false
  },

  mounted() {
    this.requsterProcesing = true
    this.fetchFleetsPolling()
    const user_id = parseInt(this.$store.state.userStore.user_name.substring(1));
    const dtrum = window.dtrum;
    dtrum.enterAction('Connexion Event', { user_name: user_id });
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.fetchingFleetsTimeoutId)
    next()
  },

  methods: {
    ...mapActions('fleetStore', ['setFetchedFleet']),
    ...mapActions('appStore', ['closeModal', 'openModal']),

    //setProcessing(payload) {
    //  this.searchProcesing = true
    //  console.log("setProcessing", this.searchProcesing, payload.state)
    //},

    cancelRequest() {
      console.log('Request canceled by user');
      this.cancelTokenSource.cancel('Request canceled by user');
      //this.searchProcesing = false
    },

    fetchAllFleets() {
      if (this.previousCancelToken) {
        // Cancel the previous request
        this.previousCancelToken.cancel('New request is being made');
      }
      // Create a new cancel token for the current request
      const cancelToken = axios.CancelToken.source();
      this.previousCancelToken = cancelToken;
      bus.$on('submitFilter', (payload) => {
        if (payload.state) {
          // Cancel the previous request
          this.previousCancelToken.cancel('New request is being made');
        }
      });
      // Make the Axios request with the cancel token
      return this.$requester
        .get(`/helicopters${this.fleetQuery}`, {
          cancelToken: cancelToken.token
        })
        .then(response => {
          // Check if response.data is a string and parse it
          const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;
          
          this.setFetchedFleet(responseData.fleets);
          this.requsterProcesing = false;
        })
        .catch((error) => {
          this.requsterProcesing = false; // Set loading state to false on error
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          }
          /* else if (error.response) {
            if (error.response.status === 503 || error.response.status === 401) {
              this.showModalWithError('Server Error', 'Data Flow Manager is currently unavailable. Sorry for the inconvenience. Please try again later');
            } 
          */
        });
    },
    async fetchFleetsPolling() {
  // A check to ensure if the component is destroyed to stop fetching
  if (this._isDestroyed || this._isBeingDestroyed) {
    return;
  }
  try {
    await this.fetchAllFleets();
  } catch (error) {
    console.error('Error fetching fleets:', error);
    this.requsterProcesing = false; // Set loading state to false on error
  }
  this.fetchingFleetsTimeoutId = setTimeout(() => {
    this.fetchFleetsPolling();
  }, 5 * 1000);
},


    async fetchFleetsPolling() {
  // A check to ensure if the component is destroyed to stop fetching
  if (this._isDestroyed || this._isBeingDestroyed) {
    return;
  }
  try {
    await this.fetchAllFleets();
  } catch (error) {
    console.error('Error fetching fleets:', error);
    this.requsterProcesing = false; // Set loading state to false on error
  }

  this.fetchingFleetsTimeoutId = setTimeout(() => {
    this.fetchFleetsPolling();
  }, 5 * 1000);
},

    modalConfirmOk() {
      this.$router.go()
    },

    showModalWithError(title, message) {
      this.confirmModal.error = true;
      this.confirmModal.message = message;
      this.confirmModal.name = title.toLowerCase().replace(/\s+/g, '') + 'Modal'; // Generate modal name
      this.openModal(this.confirmModal.name);
    },

    showError(message) {
      this.errorOccurred = true;
      this.errorMessage = message;
    },

    modalConfirmOk() {
      this.$router.go();
    },

    modalResponseError(errorMsg) {
      this.confirmModal.error = true;
      this.confirmModal.message = errorMsg;
      this.closeModal();
      this.openModal(this.confirmModal.name);
    },
  },
};
</script>

<style>
.error-message {
  padding: 20px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  color: #721c24;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-message p {
  margin: 0;
}

</style>