<template>
  <div>
    <!-- Page loading indicator -->
    <PageLoading v-if="requsterProcesing" />
    <div v-else>
      <!-- Modal Body -->
      <div class="app-modal-retrieve-body">
        <div class="modal-upload__header">
          <!-- Back Button if Helicopter Info Available -->
          <button v-if="helicopter" class="back-button" @click="toggleExpanded">Back to Overview</button>
          <div v-if="helicopter" class="registrationHeader">
            <span>{{ helicopter.registration }}</span>
          </div>
          <div v-else class="app-modal-retrieve-body_multi-title">
            <div v-for="hc in getSelectedDetailed" :key="hc.registration">
              {{ hc.registration }}
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Content with Conditions -->
      <div class="modal-retrieve" :class="{ 'hide-content-on-loading': requsterProcesing }">
        <!-- Notice Message -->
        <div class="notice">
          <v-icon class="mr-2" style="font-size: 16px;">mdi-alert-circle</v-icon>
          <label>{{ $t('retrieveMultiModal.sub_title') }}</label>
        </div>

        <div class="modal-retrieve__content">
          <!-- File Types Section -->
          <div class="columns">
            <span>{{ $t('sessionFilters.file_types') }}</span>
          </div>
          <div class="columns">
            <!-- ModalRetrieveFileTypes component -->
            <ModalRetrieveFileTypes ref="modalRetrieveFileTypes" :heli="helicopter" />
          </div>

          <!-- Filters Section -->
          <div class="columns">
            <label class="title-label">{{ $t('sessionFilters.filter') }}</label>
          </div>
          <ModalRetrieveFilterBy ref="modalRetrieveFilterBy" :heli="helicopter" />

          <!-- Footer with Buttons -->
          <div class="footer-wrapper">
            <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
            <a :disabled="!isValid()" class="button btn validationBtn" @click="retrieveFiles()">{{ $t('global.button_retrieve') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageLoading from '../../views/PageLoading'
import ModalRetrieveFilterBy from './ModalRetrieveFilterBy'
import ModalRetrieveFileTypes from './ModalRetrieveFileTypes'
import axios from 'axios'

export default {
  name: 'ModalRetrieve',
  components: {
    ModalRetrieveFilterBy,
    ModalRetrieveFileTypes,
    PageLoading
  },
  props: {
    helicopter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      requsterProcesing: false,
      loadingDetailCard: false,
      detailsModal: {
        name: 'detailsModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: { ...this.helicopter },
        title: 'detailsModal'
      }
    }
  },
  methods: {
    ...mapActions('appStore', ['openModal', 'closeModal']),
    
    // Fetch Details for Helicopter
    fetchDetailledCard() {
      this.loadingDetailCard = true
      return this.$requester
        .get(`/detailled_card`, { params: { type: this.helicopter.type, serial: this.helicopter.serial } })
        .then(response => {
          this.helicopter.session = response.data.fleets[0].aircrafts[0].session
          this.$emit('data-activity-overview');
          this.openModal(this.detailsModal.name)
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message)
          } else {
            console.log(error)
          }
        })
        .finally(() => {
          this.loadingDetailCard = false
        })
    },

    // Validate if filters and types are properly selected

    isValid() {
      if (this.$refs.modalRetrieveFilterBy != null && this.$refs.modalRetrieveFilterBy.getFiltersData().option != null) {
        return true
      } else {
        return false
      }
    },

    // Toggle Expanded State and fetch detailed card
    toggleExpanded() {
      this.fetchDetailledCard()
    },

    // Retrieve Files based on selected filters and types
    retrieveFiles() {
      if (!this.isValid()) return

      const formData = new FormData()
      const dataFromFilters = this.$refs.modalRetrieveFilterBy.getFiltersData()
      const dataFromTypes = this.$refs.modalRetrieveFileTypes.getTypesData()

      this.requsterProcesing = true
      formData.append('registration', this.helicopter.registration)
      formData.append('serial', this.helicopter.serial)
      formData.append('type', this.helicopter.type)
      formData.append('selected_files', dataFromTypes)

      // Add filters data to form
      for (let filter in dataFromFilters) {
        formData.append(filter, dataFromFilters[filter])
      }

      // Post request to retrieve files
      this.$requester
        .post('/download', formData)
        .then(response => {
          this.requsterProcesing = false
          const filename = response.data.msg.split("/").pop()
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = response.data.msg
          a.download = filename
          document.body.appendChild(a)
          a.click()
          let incompletedSessionNumber = response.headers.incompletedsession
          let SelectedSession = response.headers.selectedsession

          let inCompletedSessionMsg = this.$t('global.partial_sessions').replace("x1", incompletedSessionNumber) 
          if(incompletedSessionNumber != SelectedSession){
            inCompletedSessionMsg = inCompletedSessionMsg.replace("x2", SelectedSession)
          } else {
            inCompletedSessionMsg = this.$t('global.partial_sessions2')
          }
          let msg = (incompletedSessionNumber == 0) ? 
          this.$t('global.download_success') 
          : inCompletedSessionMsg;
          a.click();
          this.$emit('responseSuccess', msg)
          const dtrum = window.dtrum; 
          dtrum.enterAction('Retrieve Event')
        })
        .catch(error => {
          if (error.response) {
            if (error.response.status === 404) {
              let msg = this.$t('global.no_files_to_import')
              this.$emit('responseError', msg)
            } else if (error.response.status === 500) {
              // missing field
              let errMsg = this.$t('errorMsg.err')
              this.$emit('responseError', `${errMsg}`)
            } else {
              let msg = this.$t('global.nginx_timeout')
              this.$emit('responseError', msg)
            }
          }
        })
    }
  }
}
</script>

<style scoped>
/* Add custom styles here */
</style>

<style lang="scss">
  .back-button {
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 10px; 
  left: 10px; 
}

.back-button:hover {
  background-color: #e2e6ea;
}

.footer-wrapper {
      background-color: #e6f3f6;
      padding: 10px;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
</style>