<template>
  <div>
    <PageLoading v-if="requsterProcesing" />
    <div class="app-modal-upload-body" :class="{ 'hide-content-on-loading': this.requsterProcesing }">
      <div id="content_param">
        <table aria-label="Signal Status Table" class="content-array" style="border-collapse: collapse;">
          
          <!-- First Block (File Type Selection) -->
          <thead>
            <tr  class="table-header" >
              <th style="text-align: left;">{{ $t("uploadModal.hmi_1") }}</th>
            </tr>
          </thead>
          <tr>
            <td id="row" colspan="2" class="table-cell">
              <multiselect
                class="select-box"
                v-model="HMIvalue1"
                track-by="name"
                label="name"
                :placeholder="$t('uploadModal.hmi_1_placeholder')"
                :options="fileTypes"
                :searchable="false"
                :reset-after="false"
                :show-labels="false"
                :allow-empty="false"
                @input="HMIvalue2 = ''"
              ></multiselect>
            </td>
          </tr>

          <thead v-if="HMIvalue1">
            <tr class="table-header">
              <th style="text-align: left;">{{ $t("uploadModal.hmi_2") }}</th>
            </tr>
          </thead>
          <tr v-if="HMIvalue1">
            <td id="row" colspan="2" class="table-cell">
              <Multiselect
                class="select-box"
                v-model="HMIvalue2"
                :placeholder="this.$t('uploadModal.hmi_2_placeholder')"
                :options="HMIvalue1.types"
                :searchable="false"
                :reset-after="false"
                :show-labels="false"
                :allow-empty="false"
              />
            </td>
          </tr>
          <thead v-if="HMIvalue2">
            <tr class="table-header">
              <th style="text-align: left;">Hc type:</th>
            </tr>
          </thead>
          <tr  class="ExtensionTitle">
            <td  v-if="HMIvalue2" id="row" colspan="2" 
             >

              <!-- Heli Types Autocomplete (Multiple Selection) -->
              <v-autocomplete
              class = "multiselect select-box"
              style="margin bottom:15px; height: 15px !important;"
                v-model="selected_heli_types"
                :items="heli_types"
                :placeholder="$t('global.button_aircraft_type')"
                multiple
                chips
                clearable
                @change="submitFilter"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip class="custom-chip">
                    {{ item }}
                  </v-chip>
                </template>
              </v-autocomplete>


            </td>
          </tr>

          <!-- Extensions Input and Heli Types Selection -->

              <thead v-if="HMIvalue2">
                <tr class="table-header">
                  <th style="text-align: left;">{{ $t("updateUploadParameterModal.input_label_extension") }}</th>
                </tr>
              <p class="input-description">Enter one or more file extensions, separated by commas example : .lsh, .luh</p>
            </thead>


          <!-- Buttons Row: Add and Remove Parameters -->
          <tr v-if="HMIvalue2">
            <td>
              <div style="flex: 1; display: flex; flex-direction: column; align-items: flex-start;">
                <label class="input-field" style="width: 100%; margin-bottom: 10px;">
                  <input
                    v-model="HMIvalue3"
                    style="font-size: 14px; height: 30px; padding-left: 12px; border-radius: 8px; border: 1px solid #ddd; box-shadow: 0 2px 6px rgba(0,0,0,0.1); width: 100%;"
                    class = "multiselect select-box"
                    

                    :placeholder="this.$t('updateUploadParameterModal.hmi_3_placeholder')"
                    @input="validateExtensions"
                  />
                </label>

                <div v-if="invalidExtensions" style="font-size: 14px; color: red; margin-top: 5px; height: 20px; overflow: hidden;">
                  Invalid format. Please enter valid extensions (.lsh, .luh).
                </div>

                <!-- Selected Extensions List with Fixed Height and Overflow -->
                <div v-if="extensionsArray.length > 0" style="font-size: 14px; color: #333; margin-top: 12px; height: 50px; overflow-y: auto;">
                  <strong>Selected Extensions:</strong>
                  <div style="display: flex; flex-wrap: wrap;">
                    <span
                      v-for="(ext, index) in extensionsArray"
                      :key="index"
                      style="background: #e0e0e0; border-radius: 20px; padding: 4px 8px; margin-right: 8px; display: inline-block;"
                    >
                      {{ ext }}
                    </span>
                  </div>
                </div>
              </div>
            </td>
            <tr v-if="HMIvalue2">
            <td colspan="3" style="padding-top: 20px; text-align: center;">
              <div>
                <button
                :disabled="isAddRemoveBtnDisabled" 
                  @click="isAddRemoveBtnDisabled ? null : addParameter()"
                  :class="{ 'disabled-link': isAddRemoveBtnDisabled, 'button': true, 'btn': true, 'validationBtn': true }">
                  {{ $t("global.add_parameter") }}
              </button>
              <!--

                <a
                  @click="isAddRemoveBtnDisabled ? '' : removeParameter()"
                  class="button btn"
                >
                  {{ $t("global.remove_parameter") }}
                </a>
              -->
              
              </div>
            </td>
          </tr>
          </tr>
        </table>

        <!-- Accordion for heli_types using v-expansion-panel -->
        <v-expansion-panels>
          <v-expansion-panel
            v-for="hc_type in heli_types"
            :key="hc_type"
            v-model="expandedPanels"
            :value="hc_type"
          >
            <v-expansion-panel-header>
              {{ hc_type }}
            </v-expansion-panel-header>

            <v-expansion-panel-content >
              <div >
                <!-- File types grouped by heli_type -->
                <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
                  <v-expansion-panel
                    v-for="(fileType, index) in getFileTypesForHcType(hc_type)"
                    :key="index"
                    v-model="panelOpen"
                    :value="fileType"
                  >
                    <div class="column"> {{ fileType }}</div>

                    <v-expansion-panel-header>
                      <div class="column small-col" >{{$t("uploadModal.hmi_2")}}</div>
                      <div class="column large-col">Extension:</div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content>
                      <!-- Display target applications and extensions for this file type -->
                      <div v-for="(targetApp, targetAppIndex) in getTargetApplicationsForFileType(hc_type, fileType)" :key="targetAppIndex">
                        <div class="row" style="display: flex; justify-content: space-between; margin-left: 20px;">
                          <div class="small-col">
                            <p class= "TargetApp">{{ targetApp }} </p>
                          </div>
                          <div class="column large-col">
                            <div class="chips-row">

                              <div v-for="extension in getExtensionsForCombination(hc_type, fileType, targetApp)" :key="extension.id" class="chip">
                              {{ extension.extension.toLowerCase() }}

                              <!-- Trash Icon Next to Each Chip -->
                              <v-icon
                                @click="openConfirmationDialog(extension)"
                                style="cursor: pointer; margin-left: 5px; margin-bottom: 3px; font-size: 16px;"
                              >
                                mdi-delete
                              </v-icon>
                            </div>

                              <!-- Confirmation Modal -->
                              <modal :modalName="confirmModalDelete.name">
                                <ModalConfirm
                                  :message="$t('confirmModalDelete.message')"
                                  :cancel="true"
                                  @modalConfirmed="removeParameter()"
                                />
                              </modal>

                            </div>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>
    </div>
  </div>
</template>



<script>
import { mapActions } from "vuex";
import { HMI_FILES } from "../utils/constants/hmiFiles";
import PageLoading from "../views/PageLoading";
import { HELI_TYPES } from "../utils/constants/uploadSettingsHcTypes.js";
import Modal from '../components/modals/Modal';
import ModalConfirm from '../components/modals/ModalConfirm';

export default {
  name: "UploadSetting",
  beforeMount() {
    this.getExtension();
  },
  mounted() {
  this.fileTypes.forEach((_, index) => {
    this.$set(this.isAccordionOpenMap, index, true);  
  });
},
  components: {
    PageLoading,
    Modal,
    ModalConfirm,
  },
  data() {
    return {
      selected_heli_types: [],
      isAccordionOpenMap: {}, 
      fileTypes: HMI_FILES,
      HMIvalue1: "",
      HMIvalue2: "",
      HMIvalue3: "",
      HMIvalue4: "",
      requsterProcesing: false,
      extensions: [],
      heli_types: HELI_TYPES,
      size: 0,
      extensionsArray: [], 
      invalidExtensions: false, 
      panelOpen: true,
      panel: [0, 1],
      disabled: false,
      readonly: false,
      confirmModalDelete: {
        name: 'modalConfirmDelete',
        message: this.$t('confirmModalDelete.message')
      },
      extensionToRemove: null
    };
  },
  computed: {
    isAddRemoveBtnDisabled() {
      return (
        this.HMIvalue1 === "" || this.HMIvalue2 === "" || this.HMIvalue3 === "" || this.selected_heli_types.length === 0
      );
    },
    isUpdateBtnDisabled() {
      return this.HMIvalue4 === "";
    },
  },
  methods: {
    ...mapActions("appStore", ["closeModal","openModal"]),
    addParameter() {
      this.requsterProcesing = true;

      let formData = new FormData();

      if (this.HMIvalue1.name) {
        formData.append("file_type", this.HMIvalue1.name);
      }
      if (this.HMIvalue2) {
        formData.append("target_application", this.HMIvalue2.replace(/\s/g, ""));
      }
      if (this.HMIvalue3) {
        formData.append("extension", this.HMIvalue3.trim());
      }

      formData.append("selected_heli_types", this.selected_heli_types);
      formData.append("action", 1);

      this.$requester
        .post("/files/configure/extension", formData, )
        .then((response) => {
          if (response) {
            this.getExtension();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.requsterProcesing = false;
            let errMsg = error.response.data.msg;
            this.$emit("responseError", `${errMsg}`);
          }
        });
    },
    /*
    removeParameter() {
      this.requsterProcesing = true;

      let formData = new FormData();

      if (this.HMIvalue1.name) {
        formData.append("file_type", this.HMIvalue1.name);
      }
      if (this.HMIvalue2) {
        formData.append("target_application", this.HMIvalue2.replace(/\s/g, ""));
      }
      if (this.HMIvalue3) {
        formData.append("extension", this.HMIvalue3.trim());
      }

      formData.append("selected_heli_types", this.selected_heli_types);
      formData.append("action", 2);
      this.$requester
        .post("/files/configure/extension", formData)
        .then((response) => {
          if (response) {
            this.getExtension();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.requsterProcesing = false;
            let errMsg = error.response.data.msg;
            this.$emit("responseError", `${errMsg}`);
          }
        });
    },*/
    getExtension() {
      this.requsterProcesing = true;
      this.$requester
        .get("/files/configure/extension/authorized")
        .then((response) => {
          this.extensions = response.data.extensions;
          this.requsterProcesing = false;
        })
        .catch((error) => {
          console.log(error.response.data.msg);
          this.requsterProcesing = false;
        });
    },
    validateExtensions() {
      const extensionList = this.HMIvalue3.split(',').map(item => item.trim());
      this.invalidExtensions = extensionList.some(ext => !/^(\.[a-zA-Z0-9]+)$/.test(ext)); 
      this.extensionsArray = extensionList.filter(ext => /^(\.[a-zA-Z0-9]+)$/.test(ext)); 
    },
    toggleAccordion(hc_type) {
      this.$set(this.isAccordionOpenMap, hc_type, !this.isAccordionOpenMap[hc_type]);
    },
    isAccordionOpen(hc_type) {
      return !!this.isAccordionOpenMap[hc_type];
    },
    getFileTypesForHcType(hc_type) {
      return this.extensions
        .filter(extension => extension.hc_type === hc_type)
        .map(extension => extension.file_type)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    getTargetApplicationsForFileType(hc_type, fileType) {
      return this.extensions
        .filter(extension => extension.hc_type === hc_type && extension.file_type === fileType)
        .map(extension => extension.target_application)
        .filter((value, index, self) => self.indexOf(value) === index);
    },
    getExtensionsForCombination(hc_type, fileType, targetApp) {
      return this.extensions.filter(
        extension =>
          extension.hc_type === hc_type &&
          extension.file_type === fileType &&
          extension.target_application === targetApp
      );
    },
    openConfirmationDialog(extension) {
    this.extensionToRemove = extension; 
    this.openModal(this.confirmModalDelete.name)
  },
  removeParameter() {
    this.requsterProcesing = true;

    let formData = new FormData();
    this.closeModal()
    formData.append("file_type", this.extensionToRemove.file_type);
    formData.append("target_application", this.extensionToRemove.target_application.replace(/\s/g, "")); 
    formData.append("selected_heli_types",this.extensionToRemove.hc_type);
    formData.append("action", 2); 
    formData.append("extension", this.extensionToRemove.extension);

    this.$requester
        .post("/files/configure/extension", formData)
        .then((response) => {
            if (response) {
                this.getExtension(); 
            }
        })
        .catch((error) => {
            if (error.response) {
                this.requsterProcesing = false;
                let errMsg = error.response.data.msg;
                this.$emit("responseError", `${errMsg}`);
            }
        });
},


  removeExtensionFromList(id) {
    this.extensions = this.extensions.filter(extension => extension.id !== id);
  }

  },
};
</script>

<style lang="scss">
.large-col{
  width: 70% !important;
}
.small-col{
  width: 30% !important;
}

.app-modal-upload-body {
  max-width: 100%;
  max-height: 100vh; 
  overflow: auto; 
}
.app-modal-upload-body::-webkit-scrollbar {
  display: none;
}
.app-modal-upload-body {
  scroll-behavior: smooth;
}
.ExtensionTitle{
  margin-bottom: 50px;
}
.table-header {
    font-size: 16px;
    font-weight: 600;
    padding: 12px 16px;
    text-align: left;
    color: #333;
  }

  .table-cell {
    padding: 8px 16px;
  }

  .table-cell-label {
    padding: 8px 16px;
    vertical-align: middle;
  }

  /* Styles for Inputs and Select Boxes */
  .select-box {
    min-width: 200px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  .input-label {
    font-weight: 600;
    font-size: 14px;
    color: #333;
  }

  .input-field {
    font-size: 14px;
    width: 300px;
    display: flex;
    align-items: center;
  }

  .input-box {
    font-size: 14px;
    height: 30px;
    padding-left: 12px;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
  }


  .extensions-list {
    margin-top: 12px;
    font-size: 14px;
    color: #333;
  }

  .extension-chip {
    background: #e0e0e0;
    border-radius: 20px;
    padding: 4px 8px;
    margin-right: 8px;
    display: inline-block;
  }



  .column {
    padding: 8px;
    font-size: 14px;
  }

  .targetApp {
    font-size: 10px !important;
  }


  .accordion-content {
    padding: 10px;
    background-color: #f9f9f9;
  }

.chip {
  display: inline-block;
  padding: 5px 10px;
  background-color: #E0E0E2 ;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
}

.info-config-data {
  border-collapse: collapse;
}

.info-config-data th,
.info-config-data td {
  padding: 8px;
  border: 1px solid #ddd;
}

.multiselect__option--highlight{
  background: #f5f5f5 !important;
  color: black !important;

}
.multiselect__option--selected.multiselect__option--highlight{
  background: #e6f3f6 !important;
  color: black !important;
}

#title_param {
  color: #647491;
  font-weight: 400;
  line-height: 1.2;
  padding-left: 10px;
  background-color: #f6fbfc;
  height: 50px;
  padding-top: 10px;
  font-size: 12px;
  text-align: left;
  border-bottom: 1px solid #e0e3e9;
}
#content_param {
  padding: 10px;
}
.info-config-data tr td {
  border-bottom: 1px solid #002055;
}
.content-array {
  margin: 0 auto;
  vertical-align: middle;
  width: 80%;
  tr td {
    padding: 10px;
    min-width: 250px;
  }
  margin-bottom: 50px;
}
.app-modal-upload-body {
  $dark-blue: #002055;
  $pale-blue: #e6f3f6;
  $blue-lynch: #647491;
  $blue-midnight: #00205b;

  padding: 20px 70px;
  &_title {
    label th {
      color: $dark-blue;
      font-size: 14px;
    }

  }
  &_content {
    span,
    label {
      font-size: 14px;
      color: $dark-blue;
      .inputStyle {
        color: $blue-lynch;

        &:hover {
          color: #fff;
        }
      }
    }
    label {
      line-height: 2.5;
      color: $blue-lynch;
    }
    .btn-status {
      display: block;
      font-size: 15px;
      color: #647491;
    }
    .columns {
      margin: 0;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .column {
        padding: 0;
      }
    }
  }
}
.custom-chip {
    margin: 10px;
}
</style>



