<template>
  <ul>
    <li v-for="file in fileTypes" :key="file" class="modal-retrieve__checkbox-wrapper">
      <div class="modal-retrieve__checkbox checkbox-holder block">
        <input v-model="selectedFileTypes" :id="file" type="checkbox" :name="file" :value="file" />
        <label :for="file">{{ explicitFileType(file) }}</label>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { RETRIEVE_FILES_FORMATS } from '../../utils/constants/retrieveFilesFormats.js'
import { RETRIEVE_FILES_FORMATS_MW } from '../../utils/constants/retrieveFilesFormats.js'
import { RETRIEVE_FILES_FORMATS_EC225 } from '../../utils/constants/retrieveFilesFormats.js'

export default {
  name: 'ModalRetrieveFileTypes',
  props: {
    heli: {
      type: Object
    },
    selectedHelis: {
      type: Object
    }
  },
  data () {
    var fileTypes = RETRIEVE_FILES_FORMATS
    if (!this.selectedHelis || this.selectedHelis.length < 1) {
      if(this.heli.type == 'AS350_B3' || this.heli.type == 'EC130_T2' || this.heli.type  == "EC135_T4") {
        fileTypes = RETRIEVE_FILES_FORMATS_MW
      }
      if(this.heli.type == 'EC225_LP') {
        fileTypes = RETRIEVE_FILES_FORMATS_EC225
      }
    } else if (this.selectedHelis && this.selectedHelis.length == 1) {
      let heliType = this.selectedHelis[0].type
      if(heliType == 'AS350_B3' || heliType == 'EC130_T2' || heliType == "EC135_T4") {
        fileTypes = RETRIEVE_FILES_FORMATS_MW
      }
      if(heliType == 'EC225_LP') {
        fileTypes = RETRIEVE_FILES_FORMATS_EC225
      }
    }
    return {
      requsterProcesing: false,
      // loading: false,  
      fileTypes: fileTypes,
      selectedFileTypes: fileTypes.slice(0),
      modal: false
    }
  },
  methods: {
    getTypesData () {
      return this.selectedFileTypes
    },
    explicitFileType (type) {
      if (type === 'VMS_UMS') {
        return 'USAGE'
      } 
      if (type === 'MFDAU') {
        return 'HUMS'
      } 
      if (type === 'healthRawData') {
        return 'HEALTH RAW DATA'
      } 
      else if (type === 'DMAU') {
        return 'HEALTH'
      } else {
        return type
      }
    }
  }
}
</script>