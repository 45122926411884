<template>
  <div class="general-view management">
    <PageLoading style="padding-top:25%;" v-if="requesterProcessing" />
    <section v-else>
      <div class="fixed-bar">
        <div v-if="canFilter" :style="{ background: linearGradientStyle }" class="columns action-bar filter">
          <div class="column is-5">
            <input class="vuetify-input-fix filter-input" type="text" name="filter" v-model="filter"
              :placeholder="$t('management.filter_placeholder')" @keyup.enter="fetchAllEventsInfo" />
          </div>
        </div>
        <div class="checkbox-holder">
          <input type="checkbox" id="delivered" v-model="acknowledged" />
          <label for="delivered" @click="setAcknowledgeTick()">
            <h2  class="fleet-name">{{ $t("management.show_acknowledged") }}</h2>
          </label>
        </div>
      </div>
      <div v-if="!requesterProcessing && Object.keys(fleetArr).length === 0" class="no-data-page">{{
        $t('global.no_data_available') }}</div>
      <section v-if="!requesterProcessing" class="content-wrap" style="padding: 40px 24px 60px;">
        <Description :texts="[$t(`management.description_wacs`), $t(`management.description_nodata`)]"
          style='margin-top:60px;'></Description>
        <div v-for="fl in fleetArr" style="margin-bottom:40px;" v-bind:key="fl">
          <div>
            <table  aria-label="Signal Status Table" class='title_table'>
              <tr>
                <th id="col"></th>
                <th id="col"></th>
                <th id="col"></th>
              </tr>
              <tr>
                <td id="row" class='pre_button'>{{ fl.fleet }}</td>
                <td id="row" class="toggle-actions" :class="[{ expand: expanded }]" @click="expanded = !expanded">
                  <span class="icon-chevron-down"></span>
                </td>
                <td id="row">
                  <div class='bloc_actions' v-if="expanded">
                    <v-tooltip slot="append" bottom color="rgb(227, 0, 43)">
                      <div @click="openAcknowledgeConfirmModalAllFleet(fl.fleet)" style="float:left" class="action_button"
                        slot="activator">{{ $t('management.acknowledge_all') }}</div>
                      <span>{{ $t('management.tooltip_acknowledge_fleet') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="acknowledged" slot="append" bottom color="rgb(177, 216, 242)"
                      content-class="blue-tooltip">
                      <div @click="openDeleteConfirmModalAllFleet(fl.fleet)" style="float:right" class="action_button"
                        slot="activator">{{ $t('management.delete_all') }}</div>
                      <span>{{ $t('management.tooltip_delete_fleet') }}</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <!-- Purpose about fleet name is unknow -->
          <div v-for="helico in fl.helicopters" v-bind:key="helico">
            <table  aria-label="Signal Status Table" class='title_table'>
              <tr>
                <th id="col"></th>
                <th id="col"></th>
                <th id="col"></th>
              </tr>
              <tr>
                <td id="row" class='pre_button'>
                  <div class="title_serial">{{ cleanData(helico.type) }}/{{ helico.serial }} : {{ helico.registration }}
                  </div>
                </td>
                <td id="row" class="toggle-actions" :class="[{ expand: expanded }]" @click="expanded = !expanded">
                  <span class="icon-chevron-down"></span>
                </td>
                <td id="row">
                  <div class='bloc_actions' v-if="expanded">
                    <v-tooltip slot="append" bottom color="rgb(227, 0, 43)">
                      <div @click="openAcknowledgeConfirmModalAllHc(helico)" style="float:left" class="action_button"
                        slot="activator">{{ $t('management.acknowledge_all') }}</div>
                      <span>{{ $t('management.tooltip_acknowledge_hc') }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="acknowledged" slot="append" bottom color="rgb(177, 216, 242)"
                      content-class="blue-tooltip">
                      <div @click="openDeleteConfirmModalAllHc(helico)" style="float:right" class="action_button"
                        slot="activator">{{ $t('management.delete_all') }}</div>
                      <span>{{ $t('management.tooltip_delete_hc') }}</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </table>
            <div class="top-block">
              <div class="columns">
                <div class="column is-2"></div>
                <div class="column is-4">
                  <span class="standard-event">{{ $t('helicard.event_type') }}</span>
                </div>
                <div class="column is-1">
                  <span class="standard-event">{{ $t('helicard.event_date') }}</span>
                </div>
                <div class="column is-2">
                  <span class="acknowledged-by">{{ $t('helicard.acknowledge_by') }}</span>
                </div>
                <div v-if="acknowledged" class="column is-1">
                  <span class="standard-event">{{ $t('helicard.acknowledge_date') }}</span>
                </div>
                <div v-if="acknowledged" class="column is-1">
                  <span class="remove-event">{{ $t('helicard.remove_event') }}</span>
                </div>
              </div>
              <div v-for="event in helico.events" v-bind:key="event">
                <HeliEvent :event="event" :registration="helico.registration" :serial="helico.serial" :type="helico.type"
                  @showSuccessMsg="startAcknowledgeSet()" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <modal :modalName="successAcknowledgeModal.name" width="430px">
        <ModalConfirm :message="successAcknowledgeModal.message" @modalConfirmed="closeModal()" />
      </modal>
      <modal :modalName="this.confirmModalAcknowledgeAllFleet.name">
        <ModalConfirm :message="$t('management.confirmModalAcknowledgeAllFleet') + ' ' + this.selectedFleet"
          :cancel="true" @modalConfirmed="awaitAcknowledgeEventsAllFleet()" />
      </modal>
      <modal :modalName="this.confirmModalDeleteAllFleet.name">
        <ModalConfirm :message="$t('management.confirmModalDeleteAllFleet') + ' ' + this.selectedFleet" :cancel="true"
          @modalConfirmed="awaitDeleteEventsAllFleet()" />
      </modal>
      <modal :modalName="this.confirmModalAcknowledgeAllHc.name">
        <ModalConfirm
          :message="$t('management.confirmModalAcknowledgeAllHc') + ' ' + this.selectedHeli.type + '/' + this.selectedHeli.serial + ' : ' + this.selectedHeli.registration"
          :cancel="true" @modalConfirmed="awaitAcknowledgeEventsAllHc()" />
      </modal>
      <modal :modalName="this.confirmModalDeleteAllHc.name">
        <ModalConfirm
          :message="$t('management.confirmModalDeleteAllHc') + ' ' + this.selectedHeli.type + '/' + this.selectedHeli.serial + ' : ' + this.selectedHeli.registration"
          :cancel="true" @modalConfirmed="awaitDeleteEventsAllHc()" />
      </modal>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import NavLayout from '@/layouts/NavLayout'
import HeliEvent from '../components/HeliEventCard/HeliEvent'
import PageLoading from './PageLoading'

import Description from '../components/Description'
import Modal from '../components/modals/Modal'
import ModalConfirm from '../components/modals/ModalConfirm'
import utils from '../mixins/utils'

export default {
  name: 'management',
  mixins: [utils],
  components: {
    HeliEvent,
    PageLoading,
    Modal,
    ModalConfirm,
    Description,
  },
  data() {
    return {
      filter: '',
      requesterProcessing: false,
      expanded: false,
      fleetArr: [],
      selectedHeli: {},
      selectedFleet: '',
      inputValue: '',
      options: ['Reg. Nb', 'HC Type', 'Event Date'],
      acknowledged: false,
      successAcknowledgeModal: {
        name: 'successAcknowledgeModal',
        message: this.$t(`acknowledge.success_modal_msg`)
      },
      confirmModalAcknowledgeAllFleet: {
        name: 'modalConfirmAcknowledgeAllFleet',
        message: this.$t('management.confirmModalAcknowledgeAllFleet')
      },
      confirmModalDeleteAllFleet: {
        name: 'modalConfirmDeleteAllFleet',
        message: this.$t('management.confirmModalDeleteAllFleet')
      },
      confirmModalAcknowledgeAllHc: {
        name: 'modalConfirmAcknowledgeAllHc',
        message: this.$t('management.confirmModalAcknowledgeAllHc')
      },
      confirmModalDeleteAllHc: {
        name: 'modalConfirmDeleteAllHc',
        message: this.$t('management.confirmModalDeleteAllHc')
      }
    }
  },
  computed: {
    ...mapState('fleetStore', ['fleetList', 'fleetQuery']),
    ...mapGetters('userStore', ['canFilter']),
    acknowledgedParams() {
      if (this.acknowledged) {
        return true
      } else {
        return null
      }
    },
    linearGradientStyle() {
      const startColor = window.env.VUE_APP_BACKGROUND_1
      const endColor = window.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    },

  },
  created() {
    this.$emit('update:layoutComponent', NavLayout)
  },
  mounted() {
    this.requesterProcessing = true
    this.fetchEventsPolling()
  },
  beforeRouteLeave(to, from, next) {
    clearTimeout(this.fetchingFleetsTimeoutId)
    next()
  },
  methods: {
    ...mapActions('fleetStore', ['setFetchedFleet']),
    ...mapActions('appStore', ['closeModal', 'openModal']),
    fetchAllEventsInfo() {
      return this.$requester
        .get(`/get_events`, { params: this.managementQueries() })
        .then(response => {
          this.fleetArr = response.data.fleets
          this.requesterProcessing = false
        })
        .catch(error => {
          console.log(error)
          // implement dev logic here
        })
      // .finally(() => {
      //   this.requesterProcessing = false
      // })
    },
    managementQueries() {
      const query = this.$route.query
      return {
        serial: query.serial,
        type: query.type,
        acknowledged: this.acknowledgedParams,
        filter: this.filter !== '' ? this.filter : null
      }
    },
    async fetchEventsPolling() {
      // A check to ensure if the component it's destroyed to stop fetching
      if (this._isDestroyed || this._isBeingDestroyed) {
        return
      }
      await this.fetchAllEventsInfo()
      this.fetchingFleetsTimeoutId = setTimeout(() => {
        this.fetchEventsPolling()
      }, 5 * 1000)
    },
    setAcknowledgeTick() {
      this.requesterProcessing = true
      this.acknowledged = !this.acknowledged
      this.fetchAllEventsInfo()
    },
    startAcknowledgeSet() {
      this.requesterProcessing = true
      this.fetchAllEventsInfo()
      // this.openModal(this.successAcknowledgeModal.name)
    },
    async awaitAcknowledgeEventsAllFleet() {
      let selectedFleet = this.selectedFleet
      let listEvents = []
      this.fleetArr.forEach(function (fleet) {
        if (fleet.fleet === selectedFleet) {
          fleet.helicopters.forEach(function (hc) {
            hc.events.forEach(function (event) {
              if (!event.acknowledged) {
                listEvents.push({ 'event': event, 'hc': hc })
              }
            })
          })
        }
      })
      await this.acknowledgeEvents(listEvents)
      this.isLoading = true
    },
    async awaitDeleteEventsAllFleet() {
      let selectedFleet = this.selectedFleet
      let listEvents = []
      this.fleetArr.forEach(function (fleet) {
        if (fleet.fleet === selectedFleet) {
          fleet.helicopters.forEach(function (hc) {
            hc.events.forEach(function (event) {
              if (event.acknowledged) {
                listEvents.push({ 'event': event, 'hc': hc })
              }
            })
          })
        }
      })
      await this.deleteEvents(listEvents)
      this.isLoading = true
    },
    async awaitAcknowledgeEventsAllHc() {
      let heli = this.selectedHeli
      let listEvents = []
      this.fleetArr.forEach(function (fleet) {
        fleet.helicopters.forEach(function (hc) {
          if (hc.serial === heli.serial && hc.type === heli.type) {
            hc.events.forEach(function (event) {
              if (!event.acknowledged) {
                listEvents.push({ 'event': event, 'hc': hc })
              }
            })
          }
        })
      })
      await this.acknowledgeEvents(listEvents)
      this.isLoading = true
    },
    async awaitDeleteEventsAllHc() {
      let heli = this.selectedHeli
      let listEvents = []
      this.fleetArr.forEach(function (fleet) {
        fleet.helicopters.forEach(function (hc) {
          if (hc.serial === heli.serial && hc.type === heli.type) {
            hc.events.forEach(function (event) {
              if (event.acknowledged) {
                listEvents.push({ 'event': event, 'hc': hc })
              }
            })
          }
        })
      })
      await this.deleteEvents(listEvents)
      this.isLoading = true
    },
    acknowledgeEvents(listEvents) {
      let formData = new FormData()
      formData.append('events_to_acknowledge', JSON.stringify(listEvents))
      formData.append('username', this.$store.state.userStore.user_name)

      this.$requester
        .post('/acknowledge', formData)
        .then(response => {
          this.closeModal()
          this.fetchAllEventsInfo()
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteEvents(listEvents) {
      let formData = new FormData()

      formData.append('events_to_delete', JSON.stringify(listEvents))
      this.$requester
        .post('/acknowledge/delete', formData)
        .then(response => {
          this.closeModal()
          this.fetchAllEventsInfo()
        })
        .catch(error => {
          console.log(error)
        })
    },
    openAcknowledgeConfirmModalAllFleet(fleet) {
      this.selectedFleet = fleet
      this.openModal(this.confirmModalAcknowledgeAllFleet.name)
    },
    openDeleteConfirmModalAllFleet(fleet) {
      this.selectedFleet = fleet
      this.openModal(this.confirmModalDeleteAllFleet.name)
    },
    openAcknowledgeConfirmModalAllHc(helico) {
      this.selectedHeli = helico
      this.openModal(this.confirmModalAcknowledgeAllHc.name)
    },
    openDeleteConfirmModalAllHc(helico) {
      this.selectedHeli = helico
      this.openModal(this.confirmModalDeleteAllHc.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.no-padding {
  padding: 0;
}

.title_serial {
  font-size: 16px;
  color: #647491;
  font-weight: 500;
}

.pre_button {
  width: 230px;
}

.checkbox-holder {
  width: 100%;
  padding: 0 0 0 20px;
  background-color: #f6fbfc;

  h2 {
    font-size: 16px;
    color: #00205b;
    margin-bottom: 0;
    padding-left: 0;
    line-height: 41px;
  }

  label:after {
    top: 13px;
  }
}

.top-block {
  margin-bottom: 10px;

  .columns {
    margin: 0;

    .column {
      padding: 0;

      .acknowledged-by {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      span {
        font-size: 12px;
        font-weight: 300;
        color: #647491;
      }
    }
  }
}

.standard-event {
  width: 100%;
  display: flex;
}

.remove-event {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title_table td {
  padding: 0px 3px;
}

.action_button {
  border: 2px solid grey;
  height: 27px;
  border-color: #e7e7e7;
  color: #606484;
  font-size: 14px;
  margin-right: 2px;
  padding: 2px 3px;
}

.action_button:hover {
  background: #e7e7e7;
}

.bloc_actions {
  height: 27px;
}

.toggle-actions {
  padding-top: 50%;
  height: 27px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E0E3E9;
  transition: background-color .3s ease;
  z-index: 1;

  span {
    font-size: 8px;
    color: #9AA4B7;
    transform: rotate(0);
    transition: transform .4s ease-in-out, color .3s ease;
  }

  &:hover {
    background-color: #E0E3E9;

    span {
      color: grey;
    }
  }

  &.expand {
    background-color: #E0E3E9;

    span {
      color: #9AA4B7;
      transform: rotate(270deg);
    }
  }
}

.filter {
  &-input {
    background-color: white;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    font-size: 16px;

    &::placeholder {
      color: #DDD;
    }
  }
}
</style>
