<template>
  <div>
    <!-- Last flight session -->
    <div class="columns">
      <div class="column is-5">
        <input
          id="sessionInput"
          class="radio"
          type="radio"
          name="retrieveTime"
          value="sessionLast"
          v-model="option"
          @input="setByLastSession"
        />
        <label for="sessionInput" class="radio-label">{{ $t('sessionFilters.session_last') }}</label>
      </div>
    </div>

    <!-- Session number -->
    <div v-if="multi === false" class="columns">
      <div class="column is-5">
        <input
          class="radio"
          id="sessionNumberInput"
          type="radio"
          name="retrieveTime"
          value="sessionNumber"
          v-model="option"
          @input="clearInputs"
        />
        <label for="sessionNumberInput" class="radio-label">{{ $t('sessionFilters.session_number') }}</label>
      </div>
      <div class="column">
        <div v-if="option === 'sessionNumber'" class="input-basic inline">
          <v-layout>
            <v-flex sm12>
              <v-text-field v-model="sessionNumber" :label="sessionLabel" @input="setBySession" />
            </v-flex>
          </v-layout>
        </div>
        <div v-else class="input-basic inline">
          <input type="text" disabled />
        </div>
      </div>
    </div>

    <!-- All sessions of day -->
    <div class="columns">
      <div class="column is-5">
        <input
          id="allSessionsInput"
          class="radio"
          type="radio"
          name="retrieveTime"
          value="sessionDate"
          v-model="option"
          @input="clearInputs"
        />
        <label for="allSessionsInput" class="radio-label">{{ $t('sessionFilters.session_day') }}</label>
      </div>
      <div class="column">
        <div v-if="option === 'sessionDate'" class="input-basic inline">
          <v-layout>
            <v-flex sm12>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                full-width
                :max-width="'290px'"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    slot="activator"
                    :label="calendarLabel"
                    :value="formattedDate"
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  scrollable
                  @input="setSessionOfDay"
                />
              </v-menu>
            </v-flex>
          </v-layout>
        </div>
        <div v-else class="input-basic inline">
          <input type="text" disabled />
        </div>
      </div>
    </div>

    <!-- All sessions between -->
    <div class="columns">
      <div class="column is-5">
        <input
          class="radio"
          type="radio"
          id="sessionBetweenInput"
          name="retrieveTime"
          value="sessionBetween"
          v-model="option"
          @input="clearInputs"
        />
        <label for="sessionBetweenInput" class="radio-label">{{ $t('sessionFilters.session_between') }}</label>
      </div>
      <div class="column">
        <div v-if="option === 'sessionBetween'" class="input-basic inline">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="30"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                slot="activator"
                :label="calendarRangeLabel"
                :value="formattedRange"
                readonly
              />
            </template>
            <v-date-picker
              v-model="dateRange"
              range
              scrollable
              no-title
              @input="setSessionBetween"
            >
              <v-spacer></v-spacer>
              <v-btn @click="closeDatePicker" color="primary" flat>OK</v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <div v-else class="input-basic inline">
          <input type="text" disabled />
        </div>
      </div>
    </div>

    <!-- Select your sessions -->
    <div v-if="multi === false" class="columns">
      <div class="column is-5">
        <input
          id="yourSessionInput"
          class="radio"
          type="radio"
          name="retrieveTime"
          value="yourSession"
          v-model="option"
          @input="getSessions"
        />
        <label for="yourSessionInput" class="radio-label">{{ $t('sessionFilters.session_selected') }}</label>
      </div>
      <div class="column">
        <div v-if="option === 'yourSession'" class="input-basic inline">
          <v-layout>
            <v-flex sm12>
              <v-select
                v-model="sessionNumber"
                @input="setByYourSession"
                :items="yourSessionItems"
                :label="yourSessionLable"
                multiple
                required
              ></v-select>
            </v-flex>
          </v-layout>
        </div>
        <div v-else class="input-basic inline">
          <input type="text" disabled />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DateService } from '../../utils/dateService'

export default {
  name: 'ModalRetrieveFilterBy',
  props: {
    multi: {
      type: Boolean
    },
    heli: {
      type: Object
    }
  },
  data () {
    return {
      option: '',
      date: null,
      dateRange: [],
      yourSessionItems: [],
      sessionNumber: '',
      menu: false,
      dataToReturn: {},
      calendarLabel: this.$t('global.calendar_label'),
      calendarRangeLabel: this.$t('global.calendar_range_label'),
      yourSessionLable: this.$t('global.select_your_session'),
      sessionLabel: this.$t('global.session_label')
    }
  },
  computed: {
    formattedRange () {
      if (!this.dateRange.length) return ''

      return this.dateRange
        .map(d => this.$moment(d).format('D MMM YYYY'))
        .join(' - ')
    },
    formattedDate () {
      if (!this.date) return ''
      return this.$moment(this.date).format('D MMM YYYY')
    }
  },
  methods: {
    getFiltersData () {
      return this.dataToReturn
    },
    getSessions () {
      let formData = new FormData()

      formData.append('registration', this.heli.registration)
      formData.append('serial', this.heli.serial)
      formData.append('type', this.heli.type)

      this.$requester
        .post('/list_sessions', formData)
        .then(response => {
          this.yourSessionItems = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.clearInputs()
    },
    clearInputs () {
      this.date = null
      this.dateRange = []
      this.sessionNumber = ''
    },
    setByLastSession () {
      this.clearInputs()
      this.dataToReturn = {
        option: 'last_session'
      }
    },
    setBySession () {
      this.dataToReturn = {
        option: 'specific_session',
        session: this.sessionNumber
      }
    },
    setSessionOfDay () {
      const formattedDate = DateService.formatCalendarDate(this.date)
      this.dataToReturn = {
        option: 'specific_date',
        date: formattedDate
      }
      this.menu = false // Close the calendar modal after selection
    },
    setSessionBetween () {
      const formattedRange = this.dateRange.map(date =>
        DateService.formatCalendarDate(date)
      )
      this.dataToReturn = {
        option: 'between_dates',
        from_date: formattedRange[0],
        to_date: formattedRange[1]
      }
    },
    closeDatePicker () {
      this.menu = false // Close the date range picker after selecting "OK"
    },
    setByYourSession () {
      let session
      if ([...this.sessionNumber].length > 1) {
        session = [...this.sessionNumber]
      } else {
        session = [...this.sessionNumber][0]
      }
      this.dataToReturn = {
        option: 'specific_session',
        session: session
      }
    }
  }
}
</script>




<style lang="scss">
.v-input__slot {
  margin: 0 !important;
}
</style>