<template>
  <div class="card" :class="{selectedHeli: this.isHeliChecked()}">
    <!-- Table View of Card Header -->
    <header class="card-header">
      <div class="full-info-data">
        <!-- Info Bar -->
        <div class="info-bar" :class="{'connected': connect}">
          <div class="info-rectangle">
            <div class="details-column">
              <div class="header-container">

                  <div class="icon-column" @click="toggleExpanded">
                  <img :src="require(`@/assets/images/${helicopter.type}.png`)" alt="Helicopter Image">

                </div>
                <div class="signal-table-container" @click="toggleExpanded">

                <div class="serialTitle">
                  <div class="cardtitle">SERIAL</div>
                  <div class="value statusContent">{{ helicopter.serial }}</div>
                </div>
                
                <div class="RegistrationTitle">
                  <div class="cardtitle">REGISTRATION</div>
                  <div class="value statusContent">{{ helicopter.registration }}</div>
                </div>
              </div>
              </div>
              </div>
                <div class="type-background" @click="selectHeli"  >
                  <input type="checkbox" class="type-checkbox" id="checkbox1" disabled />
                  
                  <label for="checkbox1" :class="{'checked': isHeliChecked()}" class="custom-checkbox"></label>
                  <div class="type">
                    <span class="type-value">{{ helicopter.type }}</span>
                  </div>
                </div>

          </div>
            <div class="info-middle" @click="toggleExpanded"> 
              <div class="serialTitle">
              <div class="cardtitle">STATUS</div>
              <div class="value statusContent status">{{ checkHeliStatus() }}</div>
              </div>
          </div>
        </div>
      </div>
    </header>

    <!-- Card Body -->
    <div>
      <!-- Block View of Card Body -->
      <div :id="helicopter.id" class="content-body" @click="toggleExpanded">
        <div class="action-links">
          <table aria-label="Signal Status Table" class="info-data">
            <thead >
              <tr class="headbodyCard">
                <th id="col" class="eventcell">
                  <div class="signal_table label-data eventContainer">
                    <div class="eventTitle">Last connection</div>
                    <div class="value textCard">
                      <div class="event-description">
                        {{ lastLastConnexion  }}
                      </div>
                    </div>
                  </div>
                </th>

                <th id="col" class="eventcell"> 
                  <div class="signal_table label-data eventContainer">
                    <div class="eventTitle">{{ $t('helicard.connectivity') }}</div>
                      <div class="textCard" v-if="helicopter.event">
                        <div v-if="helicopter.event === 'health'" class="event-description">
                          <router-link :to="{ name: 'management', query: { serial: helicopter.serial, type: helicopter.type }}">
                            {{ $t('helicard.wacs_event') }}
                          </router-link>
                        </div>
                        <div v-else-if="helicopter.event === 'error_download'" class="event-description">
                          {{ $t('helicard.download_event') }}
                        </div>
                        <div v-else-if="helicopter.event === 'upload_error'" class="event-description">
                          {{ $t('helicard.upload_error_event') }}
                        </div>
                        <div v-else-if="helicopter.event === 'synchronized'" class="event-description">
                          {{ $t('helicard.synchronized_event') }}
                        </div>
                        <div v-else-if="helicopter.event === 'uploaded_files'" class="event-description">
                          {{ $t('helicard.uploaded_files_event') }}
                        </div>
                        <div v-else-if="helicopter.event === 'flight_session'" class="event-description">
                          {{ $t('helicard.flight_session') }}
                        </div>
                        <div v-else class="event-description">
                          {{ $t('helicard.no_event') }}
                        </div>
                      </div>
                    </div>
                  </th>
                  <th id="col">
                  <div class="tooltipContent">
                    <div class="tooltipTitle">
                    <div>
                        <template v-if="isMiniWacs(helicopter.type)">
                          <table>
                            <tr>
                              <td class="signal_description">U</td>
                              <td class="signal_description">F</td>
                              <td class="signal_description">L</td>
                            </tr>
                          </table>
                        </template>

                        <template v-else-if="isEC225(helicopter.type)">
                          <table>
                            <tr>
                              <td class="signal_description">H</td>
                              <td class="signal_description">D</td>
                              <td class="signal_description">F</td>
                              <td class="signal_description">L</td>
                            </tr>
                          </table>
                        </template>

                        <template v-else>
                          <table>
                            <tr>
                              <td class="signal_description">U</td>
                              <td class="signal_description">M</td>
                              <td class="signal_description">H</td>
                              <td class="signal_description">F</td>
                              <td class="signal_description">L</td>
                            </tr>
                          </table>
                        </template>
                      </div>
                      </div>
                    <div class="value">
                      <template v-if="isMiniWacs(helicopter.type)">
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.vms === 0, inprogress: helicopter.vms === 1, finish: helicopter.vms === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_vms') + getStatus(helicopter.vms) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                          </v-tooltip>
                        </td>
                      </template>

                      <template v-else-if="isEC225(helicopter.type)">
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.hums === 0, inprogress: helicopter.hums === 1, finish: helicopter.hums === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_hums') + getStatus(helicopter.hums) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.hms === 0, inprogress: helicopter.hms === 1, finish: helicopter.hms === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_hms') + getStatus(helicopter.hms) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                          </v-tooltip>
                        </td>
                      </template>

                      <template v-else>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.vms === 0, inprogress: helicopter.vms === 1, finish: helicopter.vms === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_vms') + getStatus(helicopter.vms) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.mnt === 0, inprogress: helicopter.mnt === 1, finish: helicopter.mnt === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_mnt') + getStatus(helicopter.mnt) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.hms === 0, inprogress: helicopter.hms === 1, finish: helicopter.hms === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_hms') + getStatus(helicopter.hms) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.fdc === 0, inprogress: helicopter.fdc === 1, finish: helicopter.fdc === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_fdc') + getStatus(helicopter.fdc) }}</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip bottom>
                            <div class="signal" :class="{disconnected: helicopter.log === 0, inprogress: helicopter.log === 1, finish: helicopter.log === 2}" slot="activator"></div>
                            <span>{{ $t('helicard.tooltip_log') + getStatus(helicopter.log) }}</span>
                          </v-tooltip>
                        </td>
                      </template>
                    </div>
                    </div>
                  </th>
              </tr>
            </thead>
          </table>

      <div class="flex-button-container">
        <button
          class="icon-download-arrow custom-button"
          @click.stop="activateRetrieveModal()"
          :class="{'animated-download': helicopter.sending_files}"
        >
          {{ $t('helicard.button_download_data') }}
        </button>
        <button v-if="isAdmin"
          class="icon-upload custom-button"
          @click.stop="activateUploadModal()"
          :class="{'animated-upload' : helicopter.intransit_file > 0}"
        >
          {{ $t('helicard.button_upload_data') }}
          <span v-if="helicopter.pending_files !== 0" class="pending-files">
            {{ helicopter.pending_files }}
          </span>
        </button>
        <button v-if="isAdmin"
          class="icon-upload custom-button"
          @click.stop="openImportModal()"
        >
          {{ $t('helicard.button_download_session') }}
        </button>
      </div>



        </div>
      </div>
    </div>
      <!-- Retrieve Modal -->
      <modal :modalName="retrieveModal.name" :title="retrieveModal.title" width="774px"  height="550px">
      <ModalRetrieve
        :helicopter="helicopter"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
         @data-activity-overview="handleDataActivityOverview"
      />
    </modal>
    <!-- Upload Modal -->
    <modal :title="uploadFilesModal.title" :modalName="uploadFilesModal.name" width="774px"  height="550px">
      <ModalUploadFiles
        :helicopter="helicopter"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
         @data-activity-overview="handleDataActivityOverview"

      />
    </modal>
    <!-- Confirm modal -->
    <modal :modalName="confirmModal.name">
      <ModalConfirm
        :message="confirmModal.message"
        :error="confirmModal.error"
        @modalConfirmed="closeModal()"
      />
    </modal>
    <!-- Import File -->
    <modal :title="importFiles.title" :modalName="importFiles.name"  width="774px"  height="550px">
      <ModalImportToGround
        :helicopter="importFiles.props"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <!-- Ban Helicopter -->
    <modal :title="banHCModal.title" :modalName="banHCModal.name" width="460px">
      <ModalBanHC 
        :helicopter="banHCModal.props"
        @responseSuccess="modalResponseSuccess" 
        @responseError="modalResponseError"
      />
    </modal>
    <!-- Unban Helicopter -->
    <modal :title="unbanHCModal.title" :modalName="unbanHCModal.name" width="460px">
      <ModalUnbanHC
        :helicopter="unbanHCModal.props" 
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <modal :modalName="detailsModal.name" title="" width="774px"  height="550px">
        <ModalDetails
          :helicopter="helicopter"
          :loading="loading"
          v-model="selectedTab"
          @data-aircraft="handleAircraftInfo"
        />
      </modal>
  </div>

</template>



<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Modal from '../modals/Modal'
import ModalConfirm from '../modals/ModalConfirm'
import ModalRetrieve from '../modals/ModalRetrieve'
import ModalDetails from '../modals/ModalDetails'
import ModalImportToGround from '../modals/ModalImportToGround'
import ModalUploadFiles from '../modals/ModalUploadFiles'
import ModalBanHC from '../modals/ModalBanHC'
import ModalUnbanHC from '../modals/ModalUnbanHC'
import { directive as onClickaway } from 'vue-clickaway'
import utils from '../../mixins/utils'
import axios from 'axios';

var offsetUTC = -(new Date().getTimezoneOffset()/60) > 0 ? '+' + -(new Date().getTimezoneOffset()/60) : -(new Date().getTimezoneOffset()/60)

export default {
  directives: {
    onClickaway: onClickaway
  },
  name: 'HeliCard',
  mixins: [utils],
  props: {
    fleetName: {
      type: String,
      required: true
    },
    helicopter: {
      type: Object,
      required: true
    }
  },
  components: {
    Modal,
    ModalRetrieve,
    ModalDetails,
    ModalConfirm,
    ModalImportToGround,
    ModalUploadFiles,
    ModalBanHC,
    ModalUnbanHC
  },
  data () {
    return {
      retrieveModal: {
        // When modal is in for loop, the name must be completely unique.
        name: 'retrieveModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'retrieveModal.title'
      },
      uploadFilesModal: {
        name: 'ModalUploadFiles' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'uploadModal.title'
      },
      importFiles: {
        name: 'importFiles' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'importFiles.title'
      },
      confirmModal: {
        name: 'confirmSuccessfulllUpload' + (this.helicopter.type + '/' + this.helicopter.serial),
        message: '',
        error: false
      },
      detailsModal: {
        // When modal is in for loop, the name must be completely unique.
        name: 'detailsModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'detailsModal'
      },

      unbanHCModal: {
        name: 'ModalUnbanHC' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'unbanHCModal.title'
      },
      banHCModal: {
        name: 'ModalBanHC' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'banHCModal.title'
      },

      connect: false,
      flagDate: false,
      transferData: true,
      importOption: false,
      uploadOpen: false,
      importDropdownShow: false,
      loading: false,
      selectedTab: 'Aircraft Information',
    }
  },
  computed: {
    ...mapState(['userStore']),
    ...mapState('appStore', ['lang']),
    ...mapState('fleetStore', ['selected', 'isHeliAdded']),
    ...mapGetters('fleetStore', ['isHeliAdded']),
    ...mapGetters('userStore', ['isAdmin', 'isAHAdmin']),
    localModel () {
      return this.selected
    },
    lastLastConnexion () {
      if (!this.helicopter.last_connexion) return 'N/A'
      let lTime = this.$moment.utc(this.helicopter.last_connexion).format('D MMM YYYY - HH:mm');

      return this.checkError(lTime)
    },


    refreshImportFiles () {
      this.importFiles.name = 'importFiles' + (this.helicopter.type + '/' + this.helicopter.serial)
      this.importFiles.props = this.helicopter
      return this.importFiles
    },
    refreshBan () {
      this.banHCModal.name = 'ModalBanHC' + (this.helicopter.type + '/' + this.helicopter.serial)
      this.banHCModal.props = this.helicopter
      return this.banHCModal
    },
    refreshUnban () {
      this.unbanHCModal.name = 'ModalUnbanHC' + (this.helicopter.type + '/' + this.helicopter.serial)
      this.unbanHCModal.props = this.helicopter
      return this.unbanHCModal
    }
  },
  methods: {
    handleDataActivityOverview() {
    this.selectedTab = 'Data Activity Overview';
  },
  handleAircraftInfo() {
    this.selectedTab = 'Aircraft Information';
  },

    checkError (time) {
      if(time.includes('Invalid')){
        return 'N/A'
      }
      else return time
    },
    toggleImportDropdown () {
      if (this.importDropdownShow) {
        this.importDropdownShow = false
      } else {
        this.importDropdownShow = true
      }
    },
    onClose () {
      this.importDropdownShow = false
    },
    ...mapActions('fleetStore', ['setFleet', 'addMachine', 'removeMachine']),
    ...mapActions('appStore', ['openModal', 'closeModal']),
    ...mapActions('userStore', ['isAdmin']),
    selectHeli () {
      if (this.isHeliChecked()) {
        this.removeMachine(this.helicopter)
      } else {
        this.addMachine(this.helicopter)
      }
    },
    isHeliChecked() {
      return this.localModel.includes((this.helicopter.type + '/' + this.helicopter.serial))
    },
    activateImportModal() {
      this.openModal(this.refreshImportFiles.name)
    },
    activateBannedModal() {
      this.openModal(this.refreshBan.name)
    },
    activateUnbannedModal() {
      this.openModal(this.refreshUnban.name)
    },
    activateRetrieveModal () {
      this.openModal(this.retrieveModal.name)
    },
    activateUploadModal () {
      this.openModal(this.uploadFilesModal.name)
    },
    modalResponseSuccess (msg) {
      this.confirmModal.error = false
      this.confirmModal.message = msg
      this.closeModal()
      this.openModal(this.confirmModal.name)
    },
    modalResponseError (errorMsg) {
      this.confirmModal.error = true
      this.confirmModal.message = errorMsg
      this.closeModal()
      this.openModal(this.confirmModal.name)
    },
    checkSession (data) {
      let status = [null, undefined, 0, '']
      if (status.includes(data)) {
        data = 'N/A'
      }
      return data
    },

  fetchDetailledCard() {
    this.loading = true; 
    return this.$requester
      .get(`/detailled_card`, { params: { type: this.helicopter.type, serial: this.helicopter.serial }}
      )
      .then(response => {
        this.helicopter.session = response.data.fleets[0].aircrafts[0].session;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log('Request canceled', error.message);
        } else {
          console.log(error);
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
  toggleExpanded() {
      this.fetchDetailledCard();
      this.openModal(this.detailsModal.name)
    },

    checkHeliStatus () {
      let status = ''
      if(this.helicopter.banned == true || this.helicopter.banned > 0){
        status = this.$t('helicard.banned')
        this.flagDate = false
        this.connect = false
      }
      else if (this.helicopter.connected > 0) {
        // status = 'CONNECTED'
        status = this.$t('helicard.connected')
        this.flagDate = true
        this.connect = true
      } else {
        // status = 'DISCONNECTED'
        status = this.$t('helicard.disconnected')
        this.flagDate = false
        this.connect = false
      }
      return status
    },
    openImportModal () {
      this.activateImportModal()
      this.importDropdownShow = false
    },
    openBanHCModal () {
      this.activateBannedModal()
      this.importDropdownShow = false
    },
    openUnbanHCModal () {
      this.activateUnbannedModal()
      this.importDropdownShow = false
    },
    away () {
      this.importDropdownShow = false
    },
    isMiniWacs (type) {
      return (type === 'AS350_B3' || type === 'EC130_T2' || type === "EC135_T4")
    },
    isEC225 (type) {
      return (type === 'EC225_LP')
    },
    getStatus (statusDigit) {
      switch (statusDigit) {
        case 1:
          return ' : in progress'
        case 2:
          return ' : transfer completed'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">

.status{
  margin-bottom: 10px;
}

.retrieveButton{
  margin-left: 10px;
}

.signal-table-container {
  display: flex; 
  justify-content: flex-end;
  align-items: center;
}
.header-container {
  display: flex;
  justify-content: space-evenly;
}

.headbodyCard{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.info-middle {
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    flex-grow: 1;

  }

.registrationTitle {
  display: flex;
  flex-direction: column;
}
.serialTitle {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.tooltipTitle{
  margin-top:3px;
  margin-bottom: 3px;
}
.tooltipContent {
  font-size: 12px;
  font-weight: 300;
  color: gray;
  margin-top: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
}


.icon-column {
  transform: rotateY(180deg);
  width: 30%;
  display: flex;
  margin-left: 3%;
  justify-content: center;
  align-items: center;
}


.statusContent{
  font-size: 16px !important;
  text-transform: uppercase;
}
.statusTitle{
  font-size: small !important;
}
.signal {
  box-shadow: 1px 1px 3px #555;
  border: 2px solid #9AA4B7;
  width: 14px;
  height: 14px;
  border-radius: 7px;
}
.disconnected {
  background: radial-gradient(#CAC9C8, #DCE1E0);
}
.inprogress {
  background: radial-gradient(#F48728, #F7B377);
}
.finish {
  background: radial-gradient(#29AC43, #9CF76F);
}


.vertical-dots-holder {
  padding-left: 10px;
}


  .loader-container {
  position: relative;
  height: 100%; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  text-align: center;
}

.info-bar {
    display: flex;
    background-color: #00205B;
    color: #F1F1F1 !important;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 2px solid #ddd;
    position: absolute;
    top: 0;
    left: 0;
  }
  .info-bar.connected {
  background-color: #29AC43; /* Green when connected */
}
  .info-rectangle {
  color: black;
  background-color: #F1F1F1;
  padding: 0.8rem;
  width: 65%;
}

.info-rectangle .details-column {
  display: flex;
  flex-direction: column; 
  flex: 1; 
}

  .type-background {
  width: 2rem; 
  background-color: #ddd;
  padding: 0.5rem;
  position: absolute; 
  bottom: 0; 
  left: 0; 
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 5px;
}

.type-checkbox {
  display: none;  /* Hide the default checkbox */
}

.custom-checkbox {
  width: 0.6rem;
  height:  0.6rem;   
  top: 0.3rem;  
  left:  0.70rem;
  border-radius: 50%; /* Circular shape */
  border: 0.5px solid #9AA4B7;
  background-color: white;
  display: inline-block;
  position: absolute;

}
.custom-checkbox.checked {
  background-color: #00205B; /* Change background color when checked */
}

.type {
  transform: rotate(270deg); /* Rotate text */
  white-space: nowrap;
  text-align: center;
  margin-top: 0.8rem; 
  margin-right: 0.175rem;

}


  .info-rectangle .type-value {
    font-size: 0.55rem;
  }

  .vertical-dots-holder {
  position: relative;
  display: inline-block;
}
.icon-dots-vertical {
  cursor: pointer;
}
.operation-dropdown {
  position: absolute;
  top: 100%;
  right: 20px;
  color: black;
  font-size: 0.8rem;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 140px; 
}

.operation-dropdown li {
  padding: 10px;
  cursor: pointer;
}
.flex-button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-top: 10px;
}

.custom-button {
  background-color: #EFF1F4;
  border: 1px solid #ddd;
  color: #000;
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 150;
  height: 45px;  
  border-radius: 5px;
  text-align: center;  
  display: flex;  
  justify-content: center;  
  align-items: center;  
  box-sizing: border-box;  
  width: 30%; 
  margin-bottom: 5px;
}

.custom-button:hover {
  background-color: #00205B;
  color: #EFF1F4;
  transform: scale(1.1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}


.divider-container {
  text-align: center; /* Center the divider within the cell */
}

.cardtitle{
  font-weight: 70;
  font-size: small;
  margin-top: 5px;
}
.downloadContainer{
  font-weight: 70;
  font-size: small;
  padding-top: 10px;
}

.eventTitle{
  font-weight: 70;
  font-size: small;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}


.v-application ol, .v-application ul {
     padding-left: 0px !important; 
}

.signal_description{
  width: 24px;
}
.dateTextCard{
  font-weight: 350;
  color: #000;
  font-size: 13px;
}
.textCard {
  font-size: 13px;
  font-weight: 350;
  color: #000;
  display: flex;  
  justify-content: center;  
  align-items: center;  
  text-align: center;
  margin-left: 5px;
}


</style>