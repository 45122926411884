<template>
  <div class="app-modal-addHC-body">
    <PageLoading v-if="loading" />
    <div v-else class="content-wrapper">
      <div class="input-basic">
        <label>{{ $t('actionModal.type_list_unmanaged_hc') }}</label>

        <multiselect
          v-model="selectedType"
          :options="uniqueTypes"
          :searchable="false"
          :reset-after="false"
          :show-labels="false"
          :allow-empty="false"
          @input="changeList()"
          class="custom-multiselect"
          placeholder="Select a type"
        />

        <template>
  <label>{{ $t('actionModal.serial_list_unmanaged_hc') }}</label>
  <v-autocomplete
    v-model="selected"
    :items="filteredHc"
    item-text="displayValue" 
    item-value="hc" 
    return-object
    :disabled="selectedType === ''"
    clearable
    @change="handleSelection"
    placeholder="Select a aircraft"  
    class="custom-autocomplete"
  ></v-autocomplete>
</template>

      </div>

      <div class="footer-wrapper">
        <a @click="closeModal()" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
        <button 
          class="button btn validationBtn" 
          :disabled="disabled"
          @click="getModalUpload()">
          {{ $t('global.button_add_hc') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageLoading from '../../views/PageLoading'

export default {
  components: {
    PageLoading
  },
  mounted () {
    this.loadUnmanagedHc()
  },
  data () {
    return {
      newHelicopter: {
        HCRegistrationNumber: '',
        hcSerialNumber: '',
        hcType: '',
        hcFleet: '',
      },
      inputStatus: [],
      errMsg: '',
      requestProcessing: false,
      unmanagedHc: [],
      disabled: true,
      loading: true,
      selectedModel: null,
      searchQuery: '',
      selectedType: ""
    }
  },
  computed: {
    sortedUnmanagedHc() {
      function compare(a, b) {
        if (a.serial.toLowerCase() < b.serial.toLowerCase()) return -1;
        if (a.serial.toLowerCase() > b.serial.toLowerCase()) return 1;
        return 0;
      }
      return this.unmanagedHc.sort(compare);
    },
    uniqueTypes() {
      return [...new Set(this.sortedUnmanagedHc.map(hc => hc.type))];
    },
    uniqueModels() {
      return [...new Set(this.sortedUnmanagedHc.map(hc => hc.model))];
    },
    filteredList() {
      let filteredHc = this.sortedUnmanagedHc;
      if (this.selectedType && this.selectedType !== '') {
        filteredHc = filteredHc.filter(hc => hc.type === this.selectedType);
      }
      if (this.selectedModel) {
        filteredHc = filteredHc.filter(hc => hc.model === this.selectedModel);
      }
      return filteredHc;
    },
    filteredHc() {
      if (!this.selectedType || this.selectedType === '') return [];
      return this.sortedUnmanagedHc
        .filter(hc =>
          hc.type === this.selectedType &&
          (hc.serial.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          hc.registration.toLowerCase().includes(this.searchQuery.toLowerCase()))
        )
        .map(hc => ({
          ...hc,
          displayValue: `${hc.serial} / ${hc.registration}` // Add a displayValue property
        }));
    },
  },
  methods: {
    ...mapActions('appStore', ['closeModal']),
    isAddHCBtnDisabled () {
      return !(
        (this.newHelicopter.HCRegistrationNumber.length >= 3) ||
        (this.selected !== '#') ||
        (this.newHelicopter.hcType.length >= 3 &&
        this.newHelicopter.hcSerialNumber.length >= 3)
      )
    },
    getModalUpload() {
      this.$requester
        .post('/helicopters', {
          serial: this.newHelicopter.hcSerialNumber,
          type: this.newHelicopter.hcType
        })
        .then(response => {
          const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

          this.$router.go();
        })
        .catch(error => {
          console.log(error);
        });
    },

    loadUnmanagedHc() {
      this.$requester
        .get('/helicopters', { params: { managed: false } })
        .then(response => {
          const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

          responseData.fleets.forEach(fleet =>
            fleet.aircrafts.forEach(hc => this.unmanagedHc.push(hc))
          );
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    getParams () {
      var selectedType = this.selectedType
      var selectedSerial = this.newHelicopter.hcSerialNumber
      if (this.selected !== '#') {
        selectedType = this.selected.type
        selectedSerial = this.selected.serial
      }
      return {
        registration: this.newHelicopter.HCRegistrationNumber,
        type: selectedType,
        serial: selectedSerial
      }
    },
    clearInput () {
      this.newHelicopter.hcSerialNumber = ''
      this.newHelicopter.hcType = ''
      this.newHelicopter.hcFleet = ''
      this.newHelicopter.HCRegistrationNumber = ''
      this.errMsg = ''
      this.selected = '#'
    },
    changeList () {
      this.autoFillHCDatq()
    },
    autoFillHCDatq () {
      this.disabled = true
      this.requestProcessing = true
      this.$requester
          .get(
            '/autoComplete', { params: this.getParams() }
          )
          .then(response => {
            if (response.data.msg) {
              this.clearInput()
              this.errMsg = this.$t(`actionModal.msg_${response.data.msg}`)
            } else {
              this.newHelicopter.hcSerialNumber = response.data.serial
              this.newHelicopter.hcType = response.data.type
              this.newHelicopter.hcFleet = response.data.fleet
              this.newHelicopter.HCRegistrationNumber = response.data.registration
              this.errMsg = ''
              this.disabled = false
            }
            this.requestProcessing = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    handleSelection(value) {
      this.selected = value;
      // Run changeList method
      this.changeList();
    },
  }
}
</script>

<style lang="scss">
.custom-multiselect .multiselect__content {
  max-height: 120px !important;
  overflow-y: auto !important; 
}

.disabled-select {
  pointer-events: none;
  background-color: #f5f5f5; 
  opacity: 0.5;
}

.app-modal-addHC-body {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  .content-wrapper {
    margin: 0 auto;
  }
  .input-basic {
    margin-bottom: 20px;
    label {
      margin-bottom: 5px;
      margin-top: 20px;
      font-weight: normal;
    }
    input {
      width: 243px;
      &.reg-num {
        border: 1px solid #0082d5;
      }
      &:disabled {
        border: none;
        background-color: rgba(154, 164, 183, 0.2);
        color: #647491;
      }
    }
  }

  .footer-wrapper {
    margin-top: 50px;
  }
}

.multiselect__option{ 
  font-size: 14px !important;
  max-height: 20px !important;
  padding-left: 10px;
  
}

.custom-autocomplete .v-list-item, .multiselect__single, .v-list-item__title {
  font-size: 14px !important;
}
.multiselect__placeholder{
  font-size: 15px !important;
  
}

.v-application .primary--text{
  color: red;
}
</style>
