<template>
  <div v-if=isReady id="app">
    <v-app>
    <component :is="layoutComponent">
      <router-view :layoutComponent.sync="layoutComponent"/>
    </component>
    <portal-target name="modalPortal"></portal-target>
    </v-app>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import DefaultLayout from './layouts/DefaultLayout'
import NavLayout from './layouts/NavLayout'
export default {
  components: {
    DefaultLayout,
    NavLayout
  },
  data () {
    return {
      layoutComponent: 'DefaultLayout',
      ready: false
    }
  },
  computed: {
    ...mapState('fleetStore', ['fleetQuery']),
    ...mapState('userStore', ['role'])
  },
  mounted () {
    this.browserChecker()
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = window.env.VUE_APP_DYNATRACE_URL
    script.crossOrigin = 'anonymous'
    document.head.appendChild(script)
  },
  watch: {
    role: function () {
      this.ready = true
      this.layoutComponent = 'NavLayout'
    }
  },
  created () {
    // this.$emit('update:layoutComponent', DefaultLayout)
    // this.closeModal()
  },
  methods: {
    ...mapActions('appStore', ['confirmIE', 'confirmEdge']),
    ...mapActions('fleetStore', ['setFetchedFleet']),
    ...mapActions('appStore', ['closeModal']),
    isReady () {
      return this.ready || this.$router.currentRoute === 'login'
    },
    browserChecker () {
      // Detect if it's IE or not
      let ua = navigator.userAgent
      if (ua.indexOf('Edge/') > -1) {
        this.confirmEdge()
      }
      // MSIE used to detect old browsers and Trident used to newer ones
      if (ua.indexOf('MSIE/') > -1 || ua.indexOf('Trident/') > -1) {
        this.confirmIE()
      }
    }
  }
}
</script>