<template>
  <section :style="{ background: linearGradientStyle }" class="action-bar dashboardHeader">
    <div class="leftButtonBlock">
      <div class="buttonHeader" style="width: 200px;"> 
        <v-autocomplete
          v-model="selectedTypes"
          :items="unmanagedHc"
          :placeholder="$t('global.button_aircraft_type')"
          multiple
          chips
          clearable
          back
          @change="submitFilter"
          class="dashboardFilter"
          no-data-text="Type not found" 
        >
          <!-- Custom selection display -->
          <template v-slot:selection="{ item, index }">
            <v-chip class="dashboardChip" v-if="selectedTypes.length <= 1 || index < 1">
              {{ item }}
            </v-chip>
            <v-chip class="dashboardChip" v-else-if="index === 1" >
              ...
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      
      <div class="buttonHeader" style="width: 200px;">
        <v-autocomplete
          v-model="selectedStatuses"
          :items="statuses"
          :placeholder="$t('global.button_status')"
          chips
          clearable
          @change="submitFilter"
          class="dashboardFilter"
          no-data-text="Status not found" 
        >
          <!-- Custom selection display -->
          <template v-slot:selection="{ item }">
            <v-chip class="dashboardChip" >
              {{ item }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
    </div>
    <div class="headerContainer">
      <div class="searchBar">
        <input
          class="vuetify-input-fix dashboardHeader-input"
          type="text"
          name="filterData"
          v-model="filterData"
          :placeholder="$t(placeholder)"
          @keyup.enter="submitFilter"
        />
      </div>
      <div class="rightButtonBlock">
        <div class="buttonHeader">
          <button
            v-if="isAHAdmin"
            class="btn hollow is-pulled-right block"
            @click="activateAddHCModal()"
          >
            {{ $t('global.button_add_hc') }}
          </button>
        </div>
      </div>
      </div>
    <modal :title="addHCModal.title" :modalName="addHCModal.name" width="430px" height="330px">
      <ModalAddHC />
    </modal>
    <modal :title="uploadFilesModal.title" :modalName="uploadFilesModal.name" width="460px">
      <ModalUploadFiles
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <modal :title="retrieveMultiModal.title" :modalName="retrieveMultiModal.name" width="774px" height="450px">
      <ModalRetrieveMulti @responseSuccess="modalResponseSuccess" @responseError="modalResponseError"/>
    </modal>
     <!-- Z2I81GIRE-162 -->
    <modal :title="removeHCModal.title" :modalName="removeHCModal.name" width="460px">
      <ModalRemoveHC @responseSuccess="modalResponseSuccess" @responseError="modalResponseError"/>
    </modal>
    <modal :title="banHCModal.title" :modalName="banHCModal.name" width="460px">
      <ModalBanHC
        :getSelectedDetailed="banHCModal.props"
        @responseSuccess="modalResponseSuccess" 
        @responseError="modalResponseError"
      />
    </modal>
    <modal :title="unbanHCModal.title" :modalName="unbanHCModal.name" width="460px">
      <ModalUnbanHC 
        :getSelectedDetailed="unbanHCModal.props"
        @responseSuccess="modalResponseSuccess" 
        @responseError="modalResponseError"
      />
    </modal>
    <modal :modalName="confirmModal.name" width="430px">
      <ModalConfirm
        :message="confirmModal.message"
        :error="confirmModal.error"
        @modalConfirmed="closeModal()"
      />
    </modal>
    
  </section>
</template>
<style lang="scss">
.dashboardHeader {
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
}

.leftButtonBlock {
  display: flex;
  align-items: center;
  gap: 20px;
}

.buttonHeader {
  display: inline-block;
  position: relative; 
}


.dashboardFilter .v-input__control input {
  caret-color: transparent !important;  
  padding-left: 10px !important;  
}

.dashboardFilter .v-input__control input::placeholder {
  color: rgba(0, 0, 0, 0.54); 
}

.dashboardFilter .v-input__control {
  height: 33px; 
  display: flex;
  align-items: center;
}

.dashboardFilter .v-input__slot {
  height: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start; 
  gap: 5px; 
}


.dashboardChip{
  font-size: 12px !important; 
  height: 22px !important; 
  margin: 2px; 
}

.v-select.v-select--chips .v-select__selections {
  min-height: 12px !important;
}

.searchBar {
  width: 45%;
}

.headerContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.dashboardHeader-input {
  background-color: transparent !important;
  height: 30px;
  width: 100%;
  font-size: 16px;
  border: 1px solid #ccc; 

}



.dashboardHeader-input::placeholder {
  color: white;
}

.dashboardFilter input::placeholder {
  color: white !important;
}
.v-autocomplete:not(.v-input--is-focused).v-select--chips input { max-height: 25px !important; }
</style>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import Modal from './modals/Modal';
import ModalAddHC from './modals/ModalAddHC';
import ModalUploadFiles from './modals/ModalUploadFiles'
import ModalConfirm from './modals/ModalConfirm'
import ModalRetrieveMulti from './modals/ModalRetrieveMulti'
import ModalRemoveHC from './modals/ModalRemoveHC'
import ModalBanHC from './modals/ModalBanHC'
import ModalUnbanHC from './modals/ModalUnbanHC'
import bus from '../event/bus';

export default {
  name: 'DashboardHeader',
  components: {
    Modal,
    ModalAddHC,
    ModalUploadFiles,
    ModalConfirm,
    ModalRetrieveMulti,
    ModalRemoveHC,
    ModalBanHC,
    ModalUnbanHC
  },
  data() {
    return {
      filterData: '',
      placeholder: 'global.search_placeholder',
      addHCModal: {
        name: 'addHC',
        title: 'global.button_add_hc'
      },
      selectedTypes: [],
      selectedStatuses: [],
      unmanagedHc: [],
      statuses: ['Connected', 'Disconnected', 'Banned'],
      uploadFilesModal: {
        name: 'ModalUploadFiles',
        title: 'uploadModal.title'
      },
      retrieveMultiModal: {
        name: 'ModalRetrieveMulti',
        title: 'retrieveMultiModal.title'
      },
      removeHCModal: {
        name: 'ModalRemoveHC',
        title: 'removeHCModal.title'
      },
      banHCModal: {
        name: 'ModalBanHC',
        title: 'banHCModal.title'
      },
      unbanHCModal: {
        name: 'ModalUnbanHC',
        title: 'unbanHCModal.title'
      },
      confirmModal: {
        name: 'confirmRetriveMulti',
        message: '',
        error: false
      }
    };
  },
  computed: {
    ...mapState(['userStore']),
    ...mapGetters('fleetStore', [
      'areAllFleetAdded',
      'isAnyActiveSelection',
      'selectedHeliCounter',
      'getSelectedDetailed'
    ]),
    ...mapGetters('userStore', ['isAdmin', 'isAHAdmin']),
    linearGradientStyle() {
      const startColor = window.env.VUE_APP_BACKGROUND_1;
      const endColor = window.env.VUE_APP_BACKGROUND_2;
      return `linear-gradient(to left, ${startColor}, ${endColor})`;
    }
  },

  mounted() {
    this.loadUnmanagedHc();
},
  methods: {
    ...mapActions('fleetStore', ['setFleetQuery', 'setFetchedFleet']),
    ...mapActions('appStore', ['openModal', 'closeModal']),
    activateAddHCModal() {
      this.openModal(this.addHCModal.name);
    },
    loadUnmanagedHc() {
  this.$requester
    .get('/helicopters', { params: { managed: true } })
    .then(response => {
      // Check if response.data is a string and parse it
      const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

      const typesSet = new Set(this.unmanagedHc.map(hc => hc.type)); // Initialize the set with existing types
      responseData.fleets.forEach(fleet => {
        fleet.aircrafts.forEach(hc => {
          if (!typesSet.has(hc.type)) { // Check if the type is already in the set
            typesSet.add(hc.type); // Add the type to the set
            this.unmanagedHc.push(hc.type); // Add the hc to unmanagedHc
          }
        });
      });
      this.loading = false;
    })
    .catch(error => {
      console.log(error);
    });
},
  submitFilter() {
    bus.$emit('submitFilter', { state: true });

    let queryParams = [];
    if (this.filterData) {
      queryParams.push(`query=${this.filterData}`);
    }
    if (this.selectedTypes && this.selectedTypes.length > 0) {
      queryParams.push(`types=${this.selectedTypes.join(',')}`);
    }
    if (this.selectedStatuses && this.selectedStatuses.length > 0) {
      queryParams.push(`statuses=${this.selectedStatuses}`);
    }

    const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
    this.setFleetQuery(queryString);

    this.$requester
      .get(`/helicopters${queryString}`)
      .then(response => {
        // Check if response.data is a string and parse it
        const responseData = typeof response.data === 'string' ? JSON.parse(response.data) : response.data;

        this.setFetchedFleet(responseData.fleets);
      })
      .catch(function (error) {
        console.log("fetch fleet error", error);
      });
  }

  }
};
</script>
