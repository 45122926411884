<template>
  <aside>
    <aside  :style ="{background:backgroundColor,  marginTop: headerTop, height: `calc(100vh - ${headerTop})`}" class="the-sidebar">
      <div class="the-sidebar__user-info-container">
        <div class="the-sidebar__avatar">
          <div v-for="data in pathToProfile" :key="data.name" @click="startGlobalLoading()">
            <router-link
              v-if="data.meta.langLabel"
              :to="data.path"
              class="link"
              style="color:white"
            ><span class="icon-user"/><span class="the-sidebar__name">{{ $store.state.userStore.user_name }}</span></router-link>
          </div>
        </div>
        <span>{{ $store.state.userStore.displayRoles[$store.state.userStore.role] }}</span>
      </div>
      <nav class="the-sidebar__navigation">
        <ul id="nav">
          <li v-for="data in navigationItems" :key="data.name" @click="startGlobalLoading()">
            <router-link
              v-if="data.meta.langLabel"
              :to="data.path"
              class="link"
            >{{ $t(`navItems.${data.meta.langLabel}`) }}</router-link>
          </li>
          <li class="link" @click="logout">
            <a>{{ $t('login.sign_out') }}</a>
          </li>
        </ul>
        <div class="the-sidebar__version">
          <div class="row-break"> <!-- Use the appropriate class for layout breaking -->
            <span class="the-sidebar__version-number">Data flow manager {{ version }}</span>
          </div>
        </div>
      </nav>
    </aside>
    <modal :modalName="confirmLogoutModal.name" width="430px">
      <ModalConfirm
        :message="confirmLogoutModal.message"
        :cancel="true"
        @modalConfirmed="modalConfirmOk"
      />
    </modal>
  </aside>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { AuthService } from '../../utils/authService'
import Modal from '../modals/Modal'
import ModalConfirm from '../modals/ModalConfirm'
import userStore from '../../store/modules/userStore.js'
export default {
  components: {
    Modal,
    ModalConfirm,
},
props: {
    headerTop: String,
  },
  data () {
    const version = window.env.VUE_APP_BRANCH || 'v0.0.0';
    return {
      navigationItems: [],
      /** Modification due to the referenced US : Z2I81GIRE-221 */
      pathToProfile: [],
      confirmLogoutModal: {
        name: 'confirmLogoutModal',
        message: ''
      },
      version: version.includes("/") ? version.split("/")[1] : version,
    }
  },
  computed: {
    ...mapState('userStore', ['role']),
    backgroundColor(){
      return window.env.VUE_APP_BACKGROUND_1
    },
  },
  watch: {
    role: function () {
      this.setNavigationItems()
    }
  },
  mounted () {
    /** Modification due to the referenced US : Z2I81GIRE-221 */
    this.setPathToProfile()
    this.setNavigationItems()  
  },
  methods: {
    ...mapActions('appStore', [
      'openModal',
      'closeModal',
      'startGlobalLoading'
    ]),
    ...mapActions('fleetStore', ['removeAll']),
    ...mapActions('userStore', ['loadUser']),
    setNavigationItems () {
      /** Modification due to the referenced US : Z2I81GIRE-221 */
      /** Modification due to the referenced US : Z2I81GIRE-226 */
      this.navigationItems = this.$router.options.routes
      if (this.role === '') {
        this.navigationItems = this.navigationItems.filter(
          item => item.name !== 'management' &&  item.name !== 'configuration' && item.name !== 'profile' && item.name !== 'alert'
        )
      } else {
        switch (this.role) {
          case 'Internal-adm':
            this.navigationItems = this.navigationItems.filter(
              item => item.name !== 'management' && item.name !== 'profile' && item.name !== 'alert'
            )
            break
          case 'MaintenanceCenter-adm':
            this.navigationItems = this.navigationItems.filter(
              item => item.name !== 'management' && item.name !== 'configuration' && item.name !== 'profile' && item.name !== 'alert'
            )
            break
          case 'Customer-adm':
            this.navigationItems = this.navigationItems.filter(
              item => item.name !== 'management' && item.name !== 'configuration' && item.name !== 'profile' && item.name !== 'alert'
            )
            break
          case 'Internal-usr':
            this.navigationItems = this.navigationItems.filter(
              item => item.name !== 'management' && item.name !== 'configuration' && item.name !== 'profile' && item.name !== 'alert'
            )
            break
          case 'MaintenanceCenter-usr':
            this.navigationItems = this.navigationItems.filter(
              item => item.name !== 'management' && item.name !== 'configuration' && item.name !== 'profile' && item.name !== 'alert'
            )
            break
          case 'Customer-usr':
            this.navigationItems = this.navigationItems.filter(
              item => item.name !== 'management' && item.name !== 'configuration' && item.name !== 'profile' && item.name !== 'alert'
            )
            break
        }
      }
    },
    logout () {
      this.confirmLogoutModal.message = this.$t('confirmLogoutModal.message')
      this.openModal(this.confirmLogoutModal.name)
    },
    modalConfirmOk () {
      this.removeAll()
      this.closeModal()
      // this.$router.push({ name: 'login' })
      AuthService.signOut()
    },
    /** Modification due to the referenced US : Z2I81GIRE-221 */
    setPathToProfile () {
      this.pathToProfile = this.$router.options.routes.filter(
        item => item.name === 'profile'
      )
    }
  }
}
</script>


<style>
  .row-break {
    display: block;
    clear: both;
  }
</style>