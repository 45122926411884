import axios from 'axios'
import userStore from '../store/modules/userStore'

const axiosInstance = axios.create({
  baseURL: typeof window !== 'undefined' ? window.env.VUE_APP_ROOT_API : 'default_value'
})

axiosInstance.interceptors.request.use((config) => {
  config.headers['Authorization'] = userStore.state.token || ''
  config.headers['idToken'] = userStore.state.idToken || ''
  return config
}, (error) => {
  return Promise.reject(error)
})

export default {
  get: axiosInstance.get,
  post: axiosInstance.post,
  put: axiosInstance.put,
  delete: axiosInstance.delete
}
