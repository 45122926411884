<template>
  <section :style ="{background: linearGradientStyle}" class="columns action-bar dashboardHeader">
    <div class="column buttons-column">
      <button v-if="isAdmin" class="btn hollow is-pulled-right block" @click="activateUploadModal()">
        <span class="icon-upload-data"></span>
        {{ $t('global.button_upload_data') }}
      </button>
    </div>
    <modal :title="uploadFilesModal.title" :modalName="uploadFilesModal.name" width="460px">
      <ModalUploadFiles
        :helicopter="getHCInfo"
        @responseSuccess="modalResponseSuccess"
        @responseError="modalResponseError"
      />
    </modal>
    <modal :modalName="confirmModal.name" width="430px">
      <ModalConfirm
        :message="confirmModal.message"
        :error="confirmModal.error"
        @modalConfirmed="closeModal()"
      />
    </modal>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import Modal from './modals/Modal'
import ModalUploadFiles from './modals/ModalUploadFiles'
import ModalConfirm from './modals/ModalConfirm'

export default {
  name: 'TransferedHeader',
  components: {
    Modal,
    ModalUploadFiles,
    ModalConfirm
  },
  props: {
    serial: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filterData: '',
      placeholder: 'global.search_placeholder',
      requsterProcesing: false,
      uploadFilesModal: {
        name: 'ModalUploadFiles',
        title: 'uploadModal.title'
      },
      confirmModal: {
        name: 'confirmRetriveMulti',
        message: '',
        error: false
      }
    }
  },
  computed: {
    ...mapGetters('fleetStore', [
      'getHCDetailed'
    ]),
    ...mapGetters('userStore', ['isAdmin']),
    getHCInfo () {
      return this.getHCDetailed({ serial: this.serial, type: this.type })
    },
    linearGradientStyle(){
      const startColor = window.env.VUE_APP_BACKGROUND_1
      const endColor = window.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    },
  },
  methods: {
    ...mapActions('fleetStore', ['setFleetQuery', 'setFetchedFleet']),
    ...mapActions('appStore', ['openModal', 'closeModal']),
    activateUploadModal () {
      this.openModal(this.uploadFilesModal.name)
    },
    modalResponseSuccess (msg) {
      this.confirmModal.error = false
      this.confirmModal.message = msg || this.confirmModal.message
      this.closeModal()
      this.openModal(this.confirmModal.name)
    },
    modalResponseError (errorMsg) {
      this.confirmModal.error = true
      this.confirmModal.message = errorMsg
      this.closeModal()
      this.openModal(this.confirmModal.name)
    }
  }
}
</script>

<style lang="scss">
.dashboardHeader {
  &-input {
    background-color: #fff !important;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    font-size: 16px;
    &::placeholder {
      color: #ddd;
    }
  }
}
</style>
