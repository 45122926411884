<template>
  <section :style ="{background: linearGradientStyle}" class="top-bar">
    <h2 class="headline">
      {{ title }}
    </h2>
  </section>
</template>

<script>
export default {
  name: 'Headline',
  props: {
    title: {
      type: String
    }
  }, 
  computed: {
    linearGradientStyle(){
      const startColor = window.env.VUE_APP_BACKGROUND_1
      const endColor = window.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    }
  }
}
</script>
