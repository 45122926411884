<template>
  <div class="configuration-bloc">
    <div class="tabs">
      <button @click="activeTab = 'upload'" :class="{ active: activeTab === 'upload' }">
        {{ $t('config.settings') }}
      </button>
      <button v-if="isAHAdmin" @click="activeTab = 'banner'" :class="{ active: activeTab === 'banner' }">
        {{ $t('config.bannerSetting') }}
      </button>
    </div>
    <div v-show="activeTab === 'upload'" class="upload-tab-content">
      <UploadSetting/>
    </div>
    <div v-show="activeTab === 'banner'" class="banner-tab-content">
      <BannerSetting/>
    </div>
  </div>
</template>

<script>
import UploadSetting from '../components/UploadSetting'
import BannerSetting from '../components/BannerSetting'
import NavLayout from '@/layouts/NavLayout'

export default {
  name: 'Configuration',
  components: {
    UploadSetting,
    BannerSetting
  },
  created () {
    this.$emit('update:layoutComponent', NavLayout)
  },
  data () {
    return {
      activeTab: 'upload',
      isAHAdmin: true
    }
  }
}
</script>

<style lang="scss" scoped>
.param_bloc {
  width: 100%;

}
  .configuration-bloc {
    margin-bottom: 60px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow-y: auto;
    height: 100%;

  }

  .tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    border-bottom: 2px solid #ddd;
  }

  .tabs button {
    padding: 1rem 2rem;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
    color: #00205b;
    transition: background-color 0.3s ease, border-bottom 0.3s ease;
    flex: 1;
  }

  .tabs button.active {
    border-bottom: 3px solid #005587;
    background-color: #f1f1f1;
  }

  .tabs button:hover {
    background-color: #e0e6ea;
  }


  .upload-tab-content {
  padding: 20px;
  background-color: #fff;
  animation: fadeIn 0.3s ease-in-out;
  flex-grow: 1; 
  }
.tab-content {
  padding: 20px;
  background-color: #fff;
  animation: fadeIn 0.3s ease-in-out;
  flex-grow: 1; 
}

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
</style>
