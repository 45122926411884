<template>
  <div>
    <PageLoading v-if="waiting_upload" :message="$t('uploadModal.waiting')" />
    <div v-else>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css">
    <div class="notice">
      <v-icon class="mr-2" style="font-size: 16px;">mdi-alert-circle</v-icon>
      <label>
        {{ $t('importFiles.sub_title') }} {{ helicopter.registration }}
      </label>
    </div>
    
      <div v-if="!showDetailsModal" >
        <div class="modal-upload__header">
          <button v-if="helicopter" class="back-button" @click="toggleExpanded">Back to Overview</button>
          <div class="registrationHeader">
            <span>{{ helicopter.registration }}</span>
          </div>
        </div>

        <div class="modal-upload" :class="{ 'hide-content-on-loading': requsterProcesing }">
          <div class="app-modal-upload-body_content" >

            <div class="table-container" v-if="inputStatus.length">
              <table class="hc-table">
                <thead>
                  <tr>
                    <th>Session(s)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(file, index) in inputStatus" :key="index">
                    <td>{{ file.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="file-upload-container">
              <button class="button btn validationBtn" @click="triggerFileInput">
                Add session(s) to submit
              </button>
              <input
                :id="helicopter.serial"
                @change="getInputData"
                type="file"
                multiple
                ref="fileInput"
                style="display: none;"
                :name="helicopter.serial"
              />
            </div>

            <div class="footer-wrapper">
              <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
              <a 
                id="upload-btn" 
                :href="isUploadBtnDisabled ? 'javascript:void(0)' : '#'" 
                :disabled="isUploadBtnDisabled" 
                :class="{ 'disabled-link': isUploadBtnDisabled, 'button': true, 'btn': true, 'validationBtn': true }" 
                @click.prevent="!isUploadBtnDisabled && uploadFiles()">
                Submit session(s)
              </a>

          </div>
          </div>
        </div>
      </div>

      <modal v-else :modalName="detailsModal.name" title="">
        <ModalDetails :helicopter="helicopter" :loading="loadingDetailCard" />
      </modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../mixins/utils'
import PageLoading from '../../views/PageLoading'
import ModalDetails from '../modals/ModalDetails';

export default {
  name: 'ModalImportFiles',
  props: {
    helicopter: {
      type: Object,
      required: false
    }
  },
  components: {
    PageLoading,
    ModalDetails 
  },
  data() {
    return {
      inputStatus: [],
      uploadDisabled: true,
      selectedColor: false,
      isFileSelected: 'empty',
      isDirectorySelected: 'empty',
      fileName: this.$t('inputs.empty'),
      directoryName: this.$t('inputs.empty_dir'),
      waiting_import: false,
      showDetailsModal: false,
      detailsModal: {
        name: 'detailsModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'detailsModal'
      }, 
    }
  },
  computed: {
    isUploadBtnDisabled() {
      return (this.uploadDisabled)
    }
  },
  methods: {
    ...mapActions('appStore', ['closeModal', 'openModal']),
    triggerFileInput() {
    this.$refs.fileInput.click();
  },
    getInputData() {
      let elementFileID = 'hmiFiles'
      let oldElementFileID = 'dirHmiFiles'
      if (this.helicopter) {
        elementFileID = this.helicopter.serial
        oldElementFileID = (this.helicopter.serial + '-dir')
      }
      let importedFile = document.getElementById(elementFileID)
      //document.getElementById(oldElementFileID).value = ''
      if (importedFile.files[0]) {
        this.uploadDisabled = false
        this.inputStatus = importedFile.files
        this.fileName = importedFile.files[0].name
        this.directoryName = this.$t('inputs.empty')
        setTimeout(() => {
          this.isFileSelected = 'selected'
          this.isDirectorySelected = 'empty'
          this.selectedColor = true
        }, 800)
      }
    },
    async uploadFiles() {
      this.waiting_import = true
      this.uploadDisabled = true
      let formData = new FormData()
      if (this.helicopter) {
        formData.append('registration', this.helicopter.registration)
        formData.append('serial', this.helicopter.serial)
        formData.append('type', this.helicopter.type)
        //formData.append('zip_file', this.inputStatus[0])
        for (let i = 0; i < this.inputStatus.length; i++) {
          let isZipFile =
            formData.append('zip_file[' + i + ']', this.inputStatus[i])
        }
      } else {
        let selectedHelicopers = this.$store.state.fleetStore.selectedDetailed.map(h => {
          return {
            registration: h.registration,
            serial: h.serial,
            type: h.type
          }
        })
        formData.append('selected_helicopters', JSON.stringify(selectedHelicopers))
      }
      // Track the event (Posthug)

      const fileInput = this.$refs.fileInput;
      //const dtrum = window.dtrum; 
      if (fileInput && fileInput.files && fileInput.files.length > 0) {
        let totalSize = 0;
        for (let i = 0; i < fileInput.files.length; i++) {
          const file = fileInput.files[i];
          totalSize += file.size; 
        }
        const fileSizeMB = totalSize / (1024 * 1024)
        //dtrum.enterAction('Import Event', { file_size: fileSizeMB });
      }
       //Can't deal with zip for now
      /*else {
        dtrum.enterAction('Import Event')
      }*/
      await this.$requester
        .post('/import', formData, {
          header: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {
          this.waiting_import = false
          let fileNames = []
          if (response.data) {
            for (let el in response.data) {
              if (response.data[el].length > 0) {
                fileNames.push(response.data[el])
              }
            }
            if ("Successfully import".includes(fileNames) || "properly downloaded but missing optional function".includes(fileNames)) {
              this.$emit('responseSuccess', this.$t('uploadModal.succes'))
            } else {
              this.$emit('responseSuccess', this.$t('uploadModal.error') + '<br>' +   fileNames)
            }
          }
        })
        .catch(error => {
        console.log('Error:', error.response ? error.response.data : error);
        if (error.response) {
          if (error.response.status === 404) {
            let msg = this.$t('global.no_files_found');
            this.$emit('responseError', this.$t('uploadModal.error') + '<br>' + msg);
          } else if (error.response.status === 500) {
            let errMsg = this.$t(error.response.data.error);
            this.$emit('responseError', this.$t('uploadModal.error') + '<br>' + `${errMsg}`);
          } else {
            let msg = this.$t('global.nginx_timeout');
            this.$emit('responseError', msg);
          }
        } else {
          let msg = this.$t('global.nginx_timeout');
          this.$emit('responseError', msg);
        }
      });

    },
    fetchDetailledCard() {
      this.loadingDetailCard = true;
      return this.$requester
        .get(`/detailled_card`, { params: { type: this.helicopter.type, serial: this.helicopter.serial }})
        .then(response => {
          this.helicopter.session = response.data.fleets[0].aircrafts[0].session;
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          this.loadingDetailCard = false;
        });
    },
    toggleExpanded() {
  this.fetchDetailledCard().then(() => {
    this.showDetailsModal = !this.showDetailsModal;
    this.openModal(this.detailsModal.name)
  });
  }
}
}
</script>

<style lang="scss">

.file-upload-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5%;

}
.disabled-link {
  cursor: not-allowed;  
  pointer-events: none;
}

</style>