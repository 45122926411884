<template>
  <div class="general-view profile">
    <div class="fixed-bar">
      <Headline />
      <section class="action-bar light">
        <div class="content-wrap">
          <a class="btn-back" @click="$router.back()">
            <span class="icon-back"></span>
            {{ $t("user.back") }}
          </a>
        </div>
      </section>
    </div>

    <div class="profile-box shadow">
      <div class="columns">
        <label class="column is-one-third">{{ $t("user.username") }}</label>
        <span class="column dynatrace" id="user_id">{{ $store.state.userStore.user_name }}</span>
      </div>
      <div class="columns">
        <label class="column is-one-third">{{ $t("user.email") }}</label>
        <span class="column">{{ $store.state.userStore.email }}</span>
      </div>
      <div class="columns">
        <label class="column is-one-third">{{ $t("user.role") }}</label>
        <span class="column dynatrace" id="role">
          {{ $store.state.userStore.displayRoles[$store.state.userStore.role] }}
        </span>
      </div>
      <div class="columns">
        <label class="column is-one-third">{{ $t("user.organization") }}</label>
          <span class="column dynatrace" id="compagny">{{ $store.state.userStore.organization }}</span>
      </div>
      <div class="columns">
        <label class="column is-one-third">{{ $t("user.lang") }}</label>
        <span class="column">
          <Multiselect v-model="multielectValue" :customLabel="getCustomLabel" :options="langOptions" :searchable="false"
            :reset-after="false" :show-labels="false" :allowEmpty="false" @select="confirmChooseLang()" />
        </span>
      </div>
      <div class="columns">
        <label class="column">Data flow manager version: {{ version }}</label>
      </div>
    </div>
    <modal :modalName="confirmLanguageModal.name" width="430px">
      <ModalConfirm :message="confirmLanguageModal.message" :cancel="true" @modalConfirmed="chooseLang()" />
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import NavLayout from "@/layouts/NavLayout";
import Headline from "../components/Headline";
import Modal from "../components/modals/Modal";
import ModalConfirm from "../components/modals/ModalConfirm";

export default {
  components: {
    Headline,
    Modal,
    ModalConfirm,
  },
  data() {
    const version = window.env.VUE_APP_BRANCH || 'v0.0.0';
    return {
      multielectValue: this.$i18n.locale,
      langOptions: ["en", "fr", "de"],
      confirmLanguageModal: {
        name: "confirmChangeLanguadgeModal",
        message: this.$t("confirmLanguageModal.message"),
      },
      organization: "",
      version: version.includes("/") ? version.split("/")[1] : version,
      buildNumber: window.env.VUE_APP_BUILD_NUMBER || "N/A",
      commitid: window.env.VUE_APP_COMMIT_ID || "N/A",
      buildDate: window.env.VUE_APP_BUILD_DATE || "N/A"
    };
  },

  computed: {
    ...mapState(["userStore"]),
    user() {
      return this.userStore;
    },
  },
  created() {
    this.$emit("update:layoutComponent", NavLayout);
  },
  methods: {
    ...mapActions("appStore", ["changeLanguage", "openModal", "closeModal"]),
    getCustomLabel(lang) {
      return this.$t(`language.${lang}`);
    },
    confirmChooseLang() {
      this.openModal(this.confirmLanguageModal.name);
    },
    chooseLang() {
      this.changeLanguage(this.multielectValue);
      this.closeModal();
    },
  },
};
</script>
