<template>
  <header :style ="{ background: backgroundColor,  top: headerTop}" class="the-header">

     <figure :style ="{background:backgroundColor}" class="the-header__logo">
      <img class="the-header__img" alt="Airbus logo" src="@/assets/images/Logo-Airbus-White.png">
    </figure>
    <h2 class="the-header__title">{{ headerTitile }} {{ transferParams }}</h2>

    <v-tooltip slot="append" bottom color="rgb(177, 216, 242)" content-class="blue-tooltip">
      <svg slot="activator" class="the-header__button" @click="downloadDoc()" :fill="backgroundColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" >
        <path d="M0 0h24v24H0z" fill="none"/>
        <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 16H8v-2h8v2zm0-4H8v-2h8v2zm-3-5V3.5L18.5 9H13z"/>
      </svg>
      <span>{{ $t('global.user_guide') }}</span>
    </v-tooltip>
  </header>
</template>

<script>
export default {
  props: {
    headerTop: String,
  },
  computed: {
    headerTitile () {
      return this.$route.meta.langLabel ? this.$t(`navItems.${this.$route.meta.langLabel}`) : ''
    },
    transferParams () {
      const query = this.$route.query
      return query.serial && query.type ? `for ${query.type}/${this.cleanData(query.serial)}` : ''
    },
    backgroundColor(){
      return window.env.VUE_APP_BACKGROUND_1
    }
  },
  methods: {
    cleanData (value) {
      return value.replace(new RegExp('_', 'g'), ' ')
    },
    downloadDoc () {
      return this.$requester
        .get(`/userGuide`, { params: { page: this.$route.meta.langLabel }, responseType: 'blob' })
        .then(response => {
          //const dtrum = window.dtrum; 
          //dtrum.enterAction('Userguide Event')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          var  pureFileName = response.headers["content-disposition"]
          var  filename = pureFileName.substring(pureFileName.indexOf('=') + 1);
          filename = filename.replace('"',"")
          filename = filename.replace('"',"")
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
