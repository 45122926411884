<template>
  <div class="general-view transfer">
    <PageLoading style="padding-top:25%;" v-if="isLoading || checkLoadingStarted"></PageLoading>
    <div v-else>
      <div class="fixed-bar">
        <!-- comment v-if canFilter in <div :style="{background: linearGradientStyle} because no more see bar for customer  -->
        <div :style="{background: linearGradientStyle}" v-if="!isSpecificHcDisplay" class="columns action-bar filter">
            <div class="column is-5">
              <input
                class="vuetify-input-fix filter-input"
                type="text"
                name="filter"
                v-model="filter"
                :placeholder="$t('management.filter_placeholder')"
                @keyup.enter="fetchAllTransfers"
              />
            </div>
            <div class="buttonContainer">
                <button @click="openAbortConfirmModalAllHc()" class="btn hollow block abortButton" v-if="showAbortButton">Abort Selected</button>
                <button @click="openDeleteConfirmModalAllHc()" class="btn hollow block" v-if="showDeleteButton">Delete Selected</button>
            </div>
        </div>

        <div class="checkbox-holder">
          <input type="checkbox" id="delivered" v-model="toggleDelivered"/>
          <label for="delivered" @click="checkDelivered">
            <h2 class="fleet-name">{{ $t("transfer.filter_delivered") }}</h2>
          </label>
        </div>
      </div>

      <div
        v-if="!isLoading && !checkLoadingStarted && Object.keys(allFleets).length === 0"
        class="no-data-page"
      >{{ $t('global.no_data_available') }} </div>

      <section class="content-wrap" :style="!transferQueries.query && 'padding: 100px 24px 90px;'">
        <h2>{{ $t("transfer.heading") }}</h2>

        <div class="transfer__fleet-table-title" v-for="(fl, index) in allFleets" :key="index">
          <div class="transfer__fleet-table" v-for="helico in fl.hc" v-bind:key="helico">
            <div class="title_serial">
              <table  aria-label="Signal Status Table" class='title_table'>
                <tr>
                  <th id="col"></th>
                  <th id="col"></th>
                </tr>
                <tr>
                  <td id="row" class='pre_button'>{{cleanData(helico.type)}}/{{helico.serial}} : {{helico.registration}}</td>
                  <td id="row">
                    <div class='bloc_actions' v-if="expanded">
                      <v-tooltip slot="append" bottom color="rgb(227, 0, 43)">
                        <div @click="openAbortConfirmModalAllHc(helico)" style="float:left" class="action_button" slot="activator">{{ $t('transfer.abort_all') }}</div>
                        <span>{{ $t('transfer.tooltip_abort_hc') }}</span>
                      </v-tooltip>
                      <v-tooltip slot="append" bottom color="rgb(177, 216, 242)" content-class="blue-tooltip">
                        <div @click="openDeleteConfirmModalAllHc(helico)" style="float:right" class="action_button" slot="activator">{{ $t('transfer.delete_all') }}</div>
                        <span>{{ $t('transfer.tooltip_delete_hc') }}</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <table aria-label="Signal Status Table" class="table is-striped">
              <thead>
                <tr>
                  <!--

                  <th id="col">
                    <div class="checkbox-holder">
                      <input type="checkbox" :id="'select-all-' + helico.serial" @change="selectAllFiles($event, helico)">
                      <label :for="'select-all-' + helico.serial"></label>
                    </div>
                  </th>
                  -->
                  <th>
                  </th>
                  <th id="col">{{ $t("transfer.upload_date") }}</th>
                  <th id="col">{{ $t("transfer.user_file") }}</th>
                  <th id="col">{{ $t("transfer.file_name") }}</th>
                  <th id="col" class="center">{{ $t("transfer.file_type") }}</th>
                  <th id="col" class="center">{{ $t("transfer.application") }}</th>
                  <th id="col" class="center-status">{{ $t("transfer.status") }}</th>
                  <th id="col">{{ $t("transfer.rejected") }}</th>
                </tr>
              </thead>
              <tbody>
                <TransferedFile
                  v-for="(file, index) in helico.files.slice().reverse()"
                  :key="index"
                  :file="file"
                  :helico="helico"
                  :selectedFiles="selectedFiles"
                  @file-selected="selectFile"
                  @openDeleteConfirmModal="openDeleteConfirmModal(file, helico)"
                  @openAbortConfirmModal="openAbortConfirmModal(file, helico)"
                ></TransferedFile>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <modal :modalName="this.confirmModalDelete.name">
        <ModalConfirm
          :message="$t('confirmModalDelete.message')"
          :cancel="true"
          @modalConfirmed="awaitDeleteTransfer(listFiles)"
        />
      </modal>
      <modal :modalName="this.confirmModalAbort.name">
        <ModalConfirm
          :message="$t('confirmModalAbort.message') +' '+  $t('confirmModalAbort.note')"
          :cancel="true"
          @modalConfirmed="awaitAbortTransfer(listFiles, true)"
        />
      </modal>
      <modal :modalName="this.confirmModalAbortAllHc.name">
        <ModalConfirm
          :message="$t('transfer.confirmModalAbortAllHcP1') + `<div class='file-list'><li>${this.selectedFiles.map(file => file.file_name).join('</li><li>')}</li></div>` +  $t('transfer.confirmModalAbortAllHcP2')"
          :cancel="true"
          @modalConfirmed="abortSelectedFiles()"
        />
      </modal>
      <modal :modalName="this.confirmModalDeleteAllHc.name">
        <ModalConfirm
          :message="$t('transfer.confirmModalDeleteAllHc') + `<div class='file-list'><li>${this.selectedFiles.map(file => file.file_name).join('</li><li>')}</li></div>` "
          :cancel="true"
          @modalConfirmed="deleteSelectedFiles()"
        />
      </modal>
      <template>
        <div class="general-view transfer">
          <v-snackbar
              v-model="snackbar.show"
              :timeout="3000"
              :color="snackbar.color"
              top
              right
            >
              {{ snackbar.message }}
              <!-- Close Button for Snackbar -->
              <template v-slot:action="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  icon
                  @click="closeSnackbar"
                  class="white--text"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-snackbar>
          </div>
        </template>
    </div>
  </div>
</template> 

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TransferedFile from '../components/TransferedFile'
import Modal from '../components/modals/Modal'
import ModalConfirm from '../components/modals/ModalConfirm'
import NavLayout from '@/layouts/NavLayout'
import PageLoading from './PageLoading'
import TransferedHeader from '../components/TransferedHeader'
import utils from '../mixins/utils'

export default {
  name: 'transfer',
  mixins: [utils],
  components: {
    TransferedFile,
    Modal,
    ModalConfirm,
    PageLoading,
    TransferedHeader
  },
  data () {
    return {
      confirmModalDelete: {
        name: 'modalConfirmDelete',
        message: this.$t('confirmModalDelete.message')
      },
      confirmModalAbort: {
        name: 'modalConfirmAbort',
        message: this.$t('confirmModalAbort.message')
      },
      confirmModalAbortAllHc: {
        name: 'modalConfirmAbortAllHc',
        message: this.$t('transfer.confirmModalAbortAllHcP1')
      },
      confirmModalDeleteAllHc: {
        name: 'modalConfirmDeleteAllHc',
        message: this.$t('transfer.confirmModalDeleteAllHc')
      },
      filter: '',
      expanded: false,
      fileData: [],
      isLoading: false,
      selectedFile: '',
      selectedFiles: [],
      selectedHelicos: [],
      selectedHeli: {},
      selectedFleet: '',
      allFleets: [],
      toggleDelivered: false,
      selectAll: {},
      snackbar: {
        show: false,
        message: '',
        color: 'green', 
      },
      
    }
  },
  computed: {
    ...mapState('fleetStore', ['fleetList', 'fleetQuery']),
    ...mapGetters('appStore', ['isLoadingStarted']),
    ...mapGetters('userStore', ['canFilter']),
    transferQueries () {
      const query = this.$route.query

      return {
        serial: query.serial || null,
        type: query.type || null,
        delivered: this.toggleDelivered || null,
        filter: this.filter !== '' ? this.filter : null
      }
    },

    checkLoadingStarted () {
      return this.isLoadingStarted
    },
    isSpecificHcDisplay () {
      const query = this.$route.query
      return query.serial && query.type
    },
    linearGradientStyle(){
      const startColor = window.env.VUE_APP_BACKGROUND_1
      const endColor = window.env.VUE_APP_BACKGROUND_2
      return `linear-gradient(to left, ${startColor}, ${endColor})`
    },
    selectedFilesCount() {
      return this.selectedFiles.length;
    },
    showAbortButton() {
      const statuses = this.selectedFiles.map(file => file.transfer_status);
      return statuses.includes('queued') || statuses.includes('failed');
    },
    showDeleteButton() {
      return this.selectedFilesCount > 0;
    },
  },
  created () {
    this.$emit('update:layoutComponent', NavLayout)
  },
  mounted () {
    this.isLoading = true
    this.$parent.$on('select-all', this.selectAll);
    this.fetchingTransfersTimeout()
  },
  watch: {
  selectAll: function(newValue) {
    const checkboxes = document.querySelectorAll('.checkbox-holder input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      checkbox.checked = newValue;
    });
  }
},
  methods: {
    ...mapActions('fleetStore', ['setFetchedFleet']),
    ...mapActions('appStore', [
      'closeModal',
      'openModal',
      'startGlobalLoading',
      'stoptGlobalLoading'
    ]),

    selectFile(selectedFile, selectedHeli) {
      const index = this.selectedFiles.findIndex((file) => file === selectedFile);
      if (index === -1) {
        this.selectedFiles.push(selectedFile);
      } else {
        this.selectedFiles.splice(index, 1);
      }
      this.$emit('file-selected', this.selectedFiles);
      this.selectedHelicos.push(selectedHeli);
    },

    selectAllFiles(event, helico) {
      const checked = event.target.checked;
      for (const file of helico.files) {
        file.selected = checked;
        if (checked) {
          this.selectFile(file, helico);
        } else {
          const index = this.selectedFiles.findIndex((selectedFile) => selectedFile === file);
          if (index !== -1) {
            this.selectedFiles.splice(index, 1);
          }
        }
      }
    },

    async checkDelivered () {
      this.toggleDelivered = !this.toggleDelivered
      this.isLoading = true
      await this.fetchAllTransfers()
    },

    async awaitAbortTransfer(listFiles = [], displaysnackbar) {
        if (this.selectedFiles.length < 1) {
          return;
        }
        for (let i = 0; i < this.selectedFiles.length; i++) {
          let selectedFile = this.selectedFiles[i];

          // Find the helicopter that contains the selected file
          let heli = this.selectedHelicos.find(helicopter =>
            helicopter.files.some(file => file.file_name === selectedFile.file_name)
          );

          // If the helicopter is found, push the file and its helicopter to the list
          if (heli) {
            listFiles.push({ 'file': selectedFile, 'hc': heli });
          }
        }
        await this.abortTransfers(listFiles)
        this.selectedFiles = []
        this.isLoading = true
        if (displaysnackbar) {
          await this.fetchAllTransfers();
          this.snackbar.message = 'File(s) successfully aborted!';
          this.snackbar.color = 'green';  
          this.snackbar.show = true;
        }

      },
    async awaitDeleteTransfer (listFiles = []) {
      if (this.selectedFiles.length < 1) {
          return;
        }
        for (let i = 0; i < this.selectedFiles.length; i++) {
          let selectedFile = this.selectedFiles[i];
          // Find the helicopter that contains the selected file
          let heli = this.selectedHelicos.find(helicopter =>
            helicopter.files.some(file => file.file_name === selectedFile.file_name)
          );
          // If the helicopter is found, push the file and its helicopter to the list
          if (heli) {
            listFiles.push({ 'file': selectedFile, 'hc': heli });
          }
        }

        console.log(listFiles);
      await this.deleteTransfers(listFiles);
      this.selectedFiles = []
      this.isLoading = true;

      await this.fetchAllTransfers();
      this.snackbar.message = 'File(s) successfully deleted!';
      this.snackbar.color = 'green';  
      this.snackbar.show = true;
    },

      abortSelectedFiles() {
      let listFiles = [];
      for (const fleet of this.allFleets) {
        for (const hc of fleet.hc) {
          for (const file of hc.files) {
            if (file.selected) {
              listFiles.push({ 'file': file, 'hc': hc });
            }
          }
        }
      }
      this.awaitAbortTransfer(listFiles, true);
    },

    deleteSelectedFiles() {
      let listFiles = [];
      for (const fleet of this.allFleets) {
        for (const hc of fleet.hc) {
          for (const file of hc.files) {
            if (file.selected) {
              listFiles.push({ 'file': file, 'hc': hc });
            }
          }
        }
      }
      this.awaitAbortTransfer(listFiles, false);
      this.awaitDeleteTransfer(listFiles);
    },

    deleteTransfers (listFiles) {
      let formData = new FormData()
      formData.append('files_to_delete', JSON.stringify(listFiles))
      return this.$requester
        .post('/delete_files', formData, {})
        .then(response => {
          this.closeModal()
          this.fetchAllTransfers()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    abortTransfers (listFiles) {
      let formData = new FormData()
      formData.append('files_to_abort', JSON.stringify(listFiles))
      return this.$requester.post('/abort', formData, {}).then(response => {
        this.closeModal()
        this.fetchAllTransfers()
      }).catch(error => {
        console.log(error.response)
      })
    },

    fetchAllTransfers () {
      return this.$requester
        .get(`/files`, { params: this.transferQueries })
        .then(response => {
          this.allFleets = response.data.result
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
          this.stoptGlobalLoading()
        })
    },
    async fetchingTransfersTimeout () {
      // A check to ensure if the component is destroyed to stop fetching
      if (this._isDestroyed || this._isBeingDestroyed) {
        return
      }
      await this.fetchAllTransfers()
    },
    openDeleteConfirmModal (file, helico) {
      this.selectedFile = file
      this.selectedHeli = helico
      this.openModal(this.confirmModalDelete.name)
    },
    openAbortConfirmModal (file, helico) {
      this.selectedFile = file
      this.selectedHeli = helico
      this.openModal(this.confirmModalAbort.name)
    },
    openAbortConfirmModalAllHc () {
      this.openModal(this.confirmModalAbortAllHc.name)
    },
    openDeleteConfirmModalAllHc () {
      this.openModal(this.confirmModalDeleteAllHc.name)
    },
    closeSnackbar() {
      this.snackbar.show = false;
    },
  }
}
</script>

<style lang="scss" scoped>
  .abortButton{
    position: relative;
    top: 23%;
    margin-right: 1%;
  }
  .buttonContainer{
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width:100%;
    height:100%;
    margin-right: 1%;
  }
.transfer {
  &__fleet-table-title {
    //margin: 34px 0 0;
  }
  &__fleet-table {
    margin-bottom: 34px;
  }
}
.title_serial {
  font-size: 16px;
  color: #647491;
  font-weight: 500;
}
.pre_button {
  width: 230px;
}
.checkbox-holder {
  width: 100%;
  padding: 0 0 0 20px;
  background-color: #f6fbfc;
  h2 {
    font-size: 16px;
    color: #00205b;
    margin-bottom: 0;
    padding-left: 0;
    line-height: 41px;
  }
  label:after {
    top: 13px;
  }
}
h2 {
  font-size: 20px;
  color: #00205b;
  margin-bottom: 10px;
}
.table {
  width: 100%;
  th {
    padding: 12px 15px;
    font-size: 14px;
    border: none;
    color: #8e9ba4;
    &:last-child {
      text-align: center;
    }
  }
}
.table.is-striped tbody tr:not(.is-selected):nth-child(odd) {
  background-color: rgba(230, 243, 246, 0.35);
}
.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: #fff;
}
.center {
  text-align: center;
}
.center-status {
  text-align: center !important;
}
span.abort {
  border: none;
  border-radius: 290486px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: center;
  width: 20px;
  background-color: #e3002b;
  &:before,
  &:after {
    background-color: white;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }
  &:before {
    height: 2px;
    width: 50%;
  }
  &:after {
    height: 50%;
    width: 2px;
  }
}
span.trash {
  position: relative;
  cursor: pointer;
}
.title_table td  {
  padding: 0px 3px;
}
.action_button {
  border: 2px solid grey;
  height: 27px;
  border-color: #e7e7e7;
  color: #606484;
  font-size: 14px;
  margin-right: 2px;
  padding: 2px 3px;
}
.action_button:hover {
    background: #e7e7e7;
}
.bloc_actions {
  height: 27px;
}
.toggle-actions {
  padding-top:50%;
  height: 27px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:#E0E3E9;
  transition: background-color .3s ease;
  z-index: 1;
  span {
    font-size: 8px;
    color: #9AA4B7;
    transform: rotate(0);
    transition: transform .4s ease-in-out, color .3s ease;
  }
  &:hover {
    background-color: #E0E3E9;
    span {
      color: grey;
    }
  }
  &.expand {
    background-color: #E0E3E9;
    span {
      color: #9AA4B7;
      transform: rotate(270deg);
    }
  }
}
.filter {
  &-input{
    background-color: white;
    width: 100%;
    height: 30px;
    padding: 0 10px;
    font-size: 16px;
    &::placeholder {
      color: #DDD;
    }
  }
}
.file-list {
  text-indent: -20px;
  padding-left: 20px;
}

</style>