<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import utils from '../../mixins/utils'
import { HMI_FILES } from '../../utils/constants/hmiFiles'
import { HMI_MINI_WACS_FILES } from '../../utils/constants/hmiMiniWacsFiles'
import { HMI_EC225_FILES } from '../../utils/constants/hmiEC225Files'
import PageLoading from '../../views/PageLoading'

export default {
  name: 'ModalUploadFiles',
  props: {
    helicopter: {
      type: Object,
      required: false
    }
  },
  components: {
    PageLoading,
  },
  data() {
    this.uploadDisabled = true
    return {
      fileTypes: HMI_FILES,
      fileMiniWacsTypes: HMI_MINI_WACS_FILES,
      fileEC225Types: HMI_EC225_FILES,
      HMIvalue1: '',
      HMIvalue2: '',
      HMIvalue3: '',
      inputStatus: [],
      uploadDisabled: true,
      selectedfile: false,
      fileName: this.$t('inputs.empty'),
      directoryName: this.$t('inputs.empty_dir'),
      priority: ['LOW', 'MEDIUM', 'HIGH'],
      waiting_upload: false,
      showDetailsModal: false,
      loadingDetailCard: false,
      detailsModal: {
        name: 'detailsModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'detailsModal'
      },
    }
  },

  computed: {
    ...mapGetters('fleetStore', ['getSelectedDetailed']),
    isAddFileDisabled() {
      return (
        this.HMIvalue1 === '' ||
        this.HMIvalue2 === '' ||
        this.HMIvalue3 === '' 
      )
    },
    fileTypes() {
    if (this.helicopter) {
      switch (this.helicopter.type) {
        case 'AS350_B3':
        case 'EC130_T2':
        case 'EC130_T4':
          return HMI_MINI_WACS_FILES;
        case 'EC225_LP':
          return HMI_EC225_FILES;
        default:
          return HMI_FILES;
      }
    }
    return HMI_FILES; // Default case
  },
  },

  methods: {
    ...mapActions('appStore', ['openModal', 'closeModal']),
    triggerFileInput() {
    this.$refs.fileInput.click();
  },
  fetchDetailledCard() {
      this.loadingDetailCard = true;
      return this.$requester
        .get(`/detailled_card`, { params: { type: this.helicopter.type, serial: this.helicopter.serial }})
        .then(response => {
          this.helicopter.session = response.data.fleets[0].aircrafts[0].session;
          this.$emit('data-activity-overview');
          this.openModal(this.detailsModal.name)

        })
        .catch(error => {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          this.loadingDetailCard = false;
        });
    },
    toggleExpanded() {
      this.fetchDetailledCard();
},


    getInputData() {
      let elementFileID = 'hmiFiles'
      if (this.helicopter) {
        elementFileID = this.helicopter.serial
      }
      let hmiFile = document.getElementById(elementFileID)

  if (hmiFile.files.length > 0) {
    this.uploadDisabled = false;

    // Clear previous inputStatus
    this.inputStatus2 = []; // Optional, if you want to reset the list on new selection

    Array.from(hmiFile.files).forEach(file => {
      this.inputStatus2.push({
        file: file, // Store the entire file object
        selected: false // Initialize selected property
      });
    });

    this.uploadDisabled = false
    this.inputStatus = hmiFile.files
    this.fileName = hmiFile.files[0].name
    this.directoryName = this.$t('inputs.empty_dir')
    setTimeout(() => {
      this.selectedfile = true;
    }, 800);
  }},

    getPriority() {
      let uploadMatrix = require('../../utils/matrix/upload_matrix.json')
      if (this.helicopter) {
        if (this.helicopter.type == 'AS350_B3' || this.helicopter.type == 'EC130_T2') {
          uploadMatrix = require('../../utils/matrix/upload_wacs_matrix.json')
        }
        if (this.helicopter.type == 'EC225_LP') {
          uploadMatrix = require('../../utils/matrix/upload_EC225_matrix.json')
        }
      } else {
        if (this.getSelectedDetailed[0].type == 'AS350_B3' || this.getSelectedDetailed[0].type == 'EC130_T2') {
          uploadMatrix = require('../../utils/matrix/upload_wacs_matrix.json')
        } 
        if (this.getSelectedDetailed[0].type == 'EC225_LP') {
          uploadMatrix = require('../../utils/matrix/upload_EC225_matrix.json')
        }
      }
      let priorityValue = uploadMatrix[this.HMIvalue1.name + '|' + this.HMIvalue2.replace(/\s/g, '')].priority
      this.HMIvalue3 = priorityValue
    },
    uploadFiles() {
      const MAX_TOTAL_SIZE = 400 * 1024 * 1024;
      this.waiting_upload = true
      this.uploadDisabled = true
      let formData = new FormData()
      let totalSize = 0; 

      for (let i = 0; i < this.inputStatus.length; i++) {
        let file = this.inputStatus[i];
        totalSize += file.size; 
        formData.append('hmi_files[' + i + ']', file);
      }

      if (totalSize > MAX_TOTAL_SIZE) {
        let errMsg = this.$t('updateUploadParameterModal.max_file_size'); 
        this.$emit('responseError', errMsg);
        this.waiting_upload = false;
        this.uploadDisabled = false;
        return;  
      }

      if (this.HMIvalue1.name) {
        formData.append('hmi_1', this.HMIvalue1.name)
      }
      if (this.HMIvalue2) {
        formData.append('hmi_2', this.HMIvalue2.replace(/\s/g, ''))
      }
      if (this.HMIvalue3) {
        formData.append('hmi_3', this.converter(this.HMIvalue3))
      }

      if (this.helicopter) {
        formData.append('registration', this.helicopter.registration)
        formData.append('serial', this.helicopter.serial)
        formData.append('type', this.helicopter.type)
      } else {
        let selectedHelicopers = this.getSelectedDetailed.map(h => {
          return {
            registration: h.registration,
            serial: h.serial,
            type: h.type
          }
        })
        formData.append(
          'selected_helicopters',
          JSON.stringify(selectedHelicopers)
        )
      }
      this.$requester
        .post('/upload', formData, {
          header: { 'content-type': 'multipart/form-data' }
        })
        .then(response => {
          if (response) {
            //const dtrum = window.dtrum; 
            //dtrum.enterAction('Upload Event', { file_type: this.HMIvalue1.name, file_target: this.HMIvalue2 })
            this.waiting_upload = false
            let msg = this.$t('dialog.success_uploaded')
            this.$emit('responseSuccess', msg + " " + JSON.stringify(response.data))
          }
        })
        .catch(error => {
          if (error.response) {
            // if (error.response.status === 409) {
            // let errMsg = this.$t('errorMsg.err')
            let errMsg = error.response.data.msg
            this.$emit('responseError', `${errMsg}`)
            // }
          } else {
            // missing field
          }
        })
    },
    converter(priority) {
      switch (priority) {
        case '':
          break

        case 'LOW':
          return 'LOW'

        case 'MEDIUM':
          return 'MED'

        case 'HIGH':
          return 'HGH'
      }
    },

  }
}
</script>
<template>
  <div>
    <!-- Page loading state -->
    <PageLoading v-if="waiting_upload" :message="$t('uploadModal.waiting')"/>

    <!-- Main content when not loading -->
    <div v-else>
      <!-- Notice Section -->
      <div class="notice">
        <v-icon class="mr-2" style="font-size: 16px;">mdi-alert-circle</v-icon>
        <label>{{ $t('uploadModal.sub_title') }}</label>
      </div>

      <div >
        <div class="app-modal-upload-body">
          <div class="modal-upload__header">
            <button v-if="helicopter" class="back-button" @click="toggleExpanded">Back to Overview</button>

            <!-- Registration Header for Helicopter -->
            <div v-if="helicopter" class="registrationHeader">
              <span>{{ helicopter.registration }}</span>
            </div>

            <!-- Registration List for other options -->
            <div v-else class="modal-retrieve__registrations-list">
              <ul class="registrationList">
                <li v-for="heli in getSelectedDetailed" :key="heli.registration">
                  {{ heli.registration }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Modal Upload Body -->
        <div class="modal-upload">
          <div class="app-modal-upload-body_content">
            <!-- HMI Selection -->
            <div class="columns">
              <label class="column is-5">{{ $t('uploadModal.hmi_1') }}</label>
              <template v-if="helicopter">
                <!-- Logic for Helicopter Type-Specific Multiselect -->
                <span v-if="helicopter.type !== 'AS350_B3' && helicopter.type !== 'EC130_T2' && helicopter.type !== 'EC130_T4' && helicopter.type !== 'EC225_LP'" class="column is-7">
                  <multiselect v-model="HMIvalue1" track-by="name" label="name"
                    :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileTypes" :searchable="false"
                    :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
                </span>
                <span v-if="helicopter.type === 'AS350_B3' || helicopter.type === 'EC130_T2' || helicopter.type === 'EC130_T4'" class="column is-7">
                  <multiselect v-model="HMIvalue1" track-by="name" label="name"
                    :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileMiniWacsTypes" :searchable="false"
                    :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
                </span>
                <span v-if="helicopter.type === 'EC225_LP'" class="column is-7">
                  <multiselect v-model="HMIvalue1" track-by="name" label="name"
                    :placeholder="$t('uploadModal.hmi_1_placeholder')" :options="fileEC225Types" :searchable="false"
                    :reset-after="false" :show-labels="false" :allow-empty="false" @input="HMIvalue2 = ''"></multiselect>
                </span>
              </template>

              <!-- Non-Helicopter Case -->
              <template v-else>
                <span class="column is-7">
                  <multiselect 
                    v-model="HMIvalue1" 
                    track-by="name" 
                    label="name"
                    :placeholder="$t('uploadModal.hmi_1_placeholder')" 
                    :options="fileTypes" 
                    :searchable="false"
                    :reset-after="false" 
                    :show-labels="false" 
                    :allow-empty="false" 
                    @input="HMIvalue2 = ''">
                  </multiselect>
                </span>
              </template>
            </div>

            <!-- HMI2 & HMI3 (subsequent selects) -->
            <div class="columns">
              <label class="column is-5">{{ $t('uploadModal.hmi_2') }}</label>
              <span class="column is-7">
                <Multiselect v-if="HMIvalue1" v-model="HMIvalue2" :placeholder="$t('uploadModal.hmi_2_placeholder')"
                  :options="HMIvalue1.types" :searchable="false" :reset-after="false" :show-labels="false"
                  :allow-empty="false" @input="getPriority()" />
              </span>
            </div>

            <div class="columns">
              <label class="column is-5">{{ $t('uploadModal.hmi_3') }}</label>
              <span class="column is-7">
                <Multiselect v-if="HMIvalue1" v-model="HMIvalue3" :placeholder="$t('uploadModal.hmi_3_placeholder')"
                  :options="priority" :searchable="false" :reset-after="false" :show-labels="false" :allow-empty="false" />
              </span>
            </div>

            <!-- File Uploads -->
            <div class= "columns importColumn" v-if="selectedfile">
              <div class="table-container uploadtab">
                <table class="hc-table">
                  <thead>
                    <tr>
                      <th class="importTable">Files to be imported</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(fileObj, index) in inputStatus2" :key="index">
                      <label :for="'file-checkbox-' + index">{{ fileObj.file.name }}</label>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- File Input & Upload Buttons -->
            <div v-if="helicopter" class="file-upload-container">
              <button :disabled="isAddFileDisabled" class="button btn validationBtn" @click="triggerFileInput">
                {{ $t('uploadModal.browse') }}
              </button>
              <input
                :id="helicopter.serial"
                @change="getInputData"
                type="file"
                multiple
                ref="fileInput"
                style="display: none;"
                :name="helicopter.serial"
              />
            </div>

            <div v-else class="file-upload-container">
              <button :disabled="isAddFileDisabled" class="button btn validationBtn" @click.stop="triggerFileInput">
                {{ $t('uploadModal.browse') }}
              </button>
              <input
                id="hmiFiles"
                @change="getInputData"
                type="file"
                name="hmiFiles"
                multiple
                ref="fileInput"
                style="display: none;"
              />
            </div>

            <!-- Footer -->
            <div class="footer-wrapper">
              <a @click="closeModal" class="button btn cancel-clean">{{ $t('global.button_cancel') }}</a>
              <a v-if="uploadDisabled !== null" id="upload-btn" :href="uploadDisabled ? 'javascript:void(0)' : '#'" 
                :disabled="uploadDisabled" :class="{ 'disabled-link': uploadDisabled, 'button': true, 'btn': true, 'validationBtn': true }" 
                @click.prevent="uploadDisabled ? null : uploadFiles()">
                {{ $t('uploadModal.button_upload') }}
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>


<style lang="scss">

.importColumn{
  margin-top: 10px;
}
.importTable{
  font-size: small;
  text-align: center !important;
}
.multiselect__option--highlight{
  background: #e6f3f6 !important;
  color: #002055 !important;

}
.multiselect__option--selected.multiselect__option--highlight{
  background: #002055 !important;
  color: #e6f3f6;
}
.table-container {
  width: 90%;
  max-height: 90px !important;
  overflow-y: auto !important;
  margin: 0 auto; 
  display: block; 
}
.uploadtab{
  margin-left: 13%;
  width: 87%;

}
.file-upload-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5%;

}
.back-button {
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.registrationHeader {
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 5px;
  padding: 5px 10px;
  left: 75%;
  color: white;
}

.back-button:hover {
  background-color: #e2e6ea;
}
.notice {
  display: flex;
  align-items: center; 
  text-align: center;
  padding: 6px;
  background-color: #e6f3f6;
  font-size: 12px;
  margin-bottom: 15px;
}
.notice label {
  overflow-wrap: break-word; 
}

.notice .v-icon {
  margin-right: 8px; 
}

.app-modal-upload-body {
  $dark-blue: #002055;
  $pale-blue: #e6f3f6;
  $blue-lynch: #647491;
  $blue-midnight: #00205b;
  max-height: 400px; 
  margin: 0 auto; 
  background-color: #fff; 

  &_title {
    label {
      color: $dark-blue;
      font-size: 14px;
      font-weight: 400;
    }

    div {
      font-weight: 600;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center; 

    span,
    label {
      font-size: 14px;
      font-weight: 400;
      color: $dark-blue;
      text-align: center; 
    }

    label {
      font-weight: 400;
      line-height: 2.5;
      color: $blue-lynch;
    }

    .btn-status {
      display: block;
      font-size: 12px;
      color: $blue-lynch;
    }

    .columns {
      justify-content: center; 
      margin: 0;
      margin-bottom: 16px;
      width: 100%; 

      &:last-of-type {
        margin-bottom: 0;
      }

      .column {
        padding: 0 10px; 
        max-width: 80%; 
      }
    }
    .footer-wrapper {
      background-color: $pale-blue;
      padding: 10px;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }


  }

  &_multi-title {
    padding: 0 0 30px;
    text-align: center; 

    .hc-reg {
      padding: 0;
      font-size: 14px;
      font-weight: 600;
      overflow: hidden;
    }
  }
}
</style>