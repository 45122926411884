<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    helicopter: {
      type: Object,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "Data Activity Overview",
    },
  },
  data() {
    return {
      allFleets: [],
      transferStatus: [],
      transferQueries: {},
      isLoading: true,
      isLoadingForFile: true,
      localSelectedTab: this.value,
      sessionInfo: {
        sessionNumber: 'N/A',
        dateTime: 'N/A',
      },
      retrieveModal: {
        name: 'retrieveModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'retrieveModal.title',
      },
      uploadFilesModal: {
        name: 'ModalUploadFiles' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'uploadModal.title',
      },
      importFiles: {
        name: 'importFiles' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'importFiles.title',
      },
      detailsModal: {
        name: 'detailsModal' + (this.helicopter.type + '/' + this.helicopter.serial),
        props: this.helicopter,
        title: 'detailsModal',
      },
    };
  },

  created() {
    this.fetchData();
  },
  methods: {
  ...mapActions('userStore', ['isAdmin']),
  ...mapActions('appStore', ['openModal', 'closeModal']),
  isMiniWacs(type) {
    return type === 'AS350_B3' || type === 'EC130_T2';
  },
  changeTab(tab) {
    this.localSelectedTab = tab;
  },
  checkSession(value) {
    return value || 'N/A';
  },
  activateRetrieveModal() {
    this.closeModal(this.detailsModal.name);
    this.openModal(this.retrieveModal.name);
  },
  activateUploadModal() {
    this.closeModal();
    this.openModal(this.uploadFilesModal.name);
  },
  filterAndDisplayFiles() {
    const filteredFiles = [];
    this.allFleets.forEach(fleet => {
      fleet.hc.forEach(hc => {
        if (hc.type === this.helicopter.type && hc.serial === this.helicopter.serial) {
          filteredFiles.push(...hc.files);
        }
      });
    });

    filteredFiles.sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date));

    this.transferStatus = filteredFiles.slice(0, 3).map(file => {
      file.formatted_upload_date = this.$moment.utc(file.upload_date).format('D MMM YYYY - HH:mm');
      return file;
    });
  },
  async fetchData() {
    try {
      this.isLoadingForFile = true;
      // Fetch files and last session in parallel
      await Promise.all([this.fetchFiles(), this.fetchLastSession()]);
    } catch (error) {
      console.log("Error fetching data", error);
    } finally {
      this.isLoadingForFile = false;
    }
  },
  async fetchFiles() {
    try {
      const response = await this.$requester.get(`/files`, { params: this.transferQueries });
      this.allFleets = response.data.result;
      this.filterAndDisplayFiles();
    } catch (error) {
      console.log(error);
    }
  },
  async fetchLastSession() {
    try {
      const formData = new FormData();
      formData.append('registration', this.helicopter.registration);
      formData.append('serial', this.helicopter.serial);
      formData.append('type', this.helicopter.type);
      const response = await this.$requester.post('/list_sessions', formData);
      const sessions = response.data;
      if (sessions.length > 0) {
        this.updateSessionInfo(sessions[0]);
      }
    } catch (error) {
      console.log(error);
    }
  },
  updateSessionInfo(session) {
    const parts = session.split('_');
    this.sessionInfo.sessionNumber = parts[2];
    this.sessionInfo.dateTime = parts[3] + parts[4];
    const year = this.sessionInfo.dateTime.slice(0, 4);
    const month = this.sessionInfo.dateTime.slice(4, 6);
    const day = this.sessionInfo.dateTime.slice(6, 8);
    const hour = this.sessionInfo.dateTime.slice(8, 10);
    const minute = this.sessionInfo.dateTime.slice(10, 12);

    const formattedDateString = `${year}-${month}-${day} ${hour}:${minute}`;

    this.sessionInfo.dateTime = this.$moment(formattedDateString, 'YYYY-MM-DD HH:mm').format('D MMM YYYY - HH:mm');
  },
  checkError(time) {
    if (time.includes('Invalid')) {
      return 'N/A';
    }
    return time;
  },
  checkHeliStatus() {
    let status = '';
    if (this.helicopter.banned === true || this.helicopter.banned > 0) {
      status = this.$t('helicard.banned');
      this.flagDate = false;
      this.connect = false;
    } else if (this.helicopter.connected > 0) {
      status = this.$t('helicard.connected');
      this.flagDate = true;
      this.connect = true;
    } else {
      status = this.$t('helicard.disconnected');
      this.flagDate = false;
      this.connect = false;
    }
    return status;
  }
},


  watch: {
    expanded(newValue) {
      document.body.style.overflow = newValue ? 'hidden' : ''; // Block/restore scrolling
    }
  },

  beforeMount() {
    this.fetchFiles();
    this.fetchLastSession();
  },

  beforeDestroy() {
    this.$emit('data-aircraft');
    document.body.style.overflow = ''; // Ensure scrolling is restored when the component is destroyed
  },
  computed: {
    ...mapGetters('userStore', ['isAdmin', 'isAHAdmin']),
    refreshImportFiles() {
      this.importFiles.name = 'importFiles' + (this.helicopter.type + '/' + this.helicopter.serial);
      this.importFiles.props = this.helicopter;
      return this.importFiles;
    },
    lastUploadFormatedDateAndTime () {
    if (!this.helicopter.last_upload || this.helicopter.last_upload === '0000-00-00 00:00:00') return 'N/A';
    let lTime = this.$moment.utc(this.helicopter.last_upload).format('D MMM YYYY - HH:mm');
    return this.checkError(lTime);
    },
    lastDownloadFormatedDateAndTime () {
      if (!this.helicopter.last_download || this.helicopter.last_download === '0000-00-00 00:00:00') return 'N/A';
      let lTime = this.$moment.utc(this.helicopter.last_download).format('D MMM YYYY - HH:mm');
      return this.checkError(lTime);
    },
    selectedTab() {
      return this.value;
    }
  },
};
</script>
<template>
  <div class="full-info">
    <div v-if="loading" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-else class="full-info-data">
      <!-- Info Bar -->
      <!-- Parent Container -->
      <div class="parent-container">
        <!-- Info Bar -->
        <div class="info-bar">
          <div class="info-rectangle">
            <div class="details-column">

              <div class="signal-table-container">
                  <div class="icon-column aircraft-icon">
                  <img :src="require(`@/assets/images/${helicopter.type}.png`)" alt="Helicopter Image">

                </div>

                <div class="serialTitle">
                  <div class="cardtitle">SERIAL</div>
                  <div class="idTitle">{{ helicopter.serial }}</div>
                </div>
                
                <div class="RegistrationTitle">
                  <div class="cardtitle">REGISTRATION</div>
                  <div class="idTitle">{{ helicopter.registration }}</div>
                </div>
              </div>
              </div>
              <div class="type-background">
                <div class="type">
                  <span class="type-value">{{ helicopter.type }}</span>
              </div>
            </div>
          </div>

            <div class="info-middle">
              <div class="serialTitle detailStatus">
              <div class="cardtitle">STATUS</div>
              <div class="value statusContent detailStatusValue">{{ checkHeliStatus() }}</div>
            </div>

          </div>
        </div>
        
        <!-- Tabs Navigation -->
          <div class="tabs">
            <button @click.stop="changeTab('Aircraft Information')" :class="{ active: localSelectedTab === 'Aircraft Information' }">Aircraft Information</button>
            <button @click="changeTab('Data Activity Overview')" :class="{ active: localSelectedTab === 'Data Activity Overview' }">Data Activity Overview</button>
          </div>
      </div>


      <!-- Tabs Content -->
      <div v-show="localSelectedTab === 'Aircraft Information'" class="tab-content">
        <!-- Use the Modal component to display the details -->
        <div :modalName="'DetailsModal'" :title="'Details'">
          <!-- Display Mini WACS or Non-Mini WACS Tables based on helicopter type -->
          <template v-if="isMiniWacs(helicopter.type)">
            <table aria-label="Signal Status Table" style="margin-bottom: 15px;" class="hc-table">
              <thead>
                <tr>
                  <th id="col"></th>
                  <th id="col">HW Part Number</th>
                  <th id="col">HW S/N</th>
                  <th id="col">SW Part Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AEC</td>
                  <td>{{ checkSession(helicopter.session.acs_hw_pn) }}</td>
                  <td>{{ checkSession(helicopter.session.acs_hw_sn) }}</td>
                  <td>{{ checkSession(helicopter.session.acs_sw_pn) }}</td>
                </tr>
              </tbody>
            </table>
            <table aria-label="Signal Status Table" style="margin-bottom: 15px;" class="hc-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('helicard.location') }}</th>
                  <th id="col">Last Import</th>
                  <th id="col">Last Export</th>
                </tr> 
              </thead>
              <tbody>
                <tr>
                  <td>{{ checkSession(helicopter.session.gps_lat) }}</td>
                  <td>{{ checkSession(helicopter.session.gps_lon) }}</td>
                  <td>{{ lastUploadFormatedDateAndTime }}</td>
                  <td>{{ lastDownloadFormatedDateAndTime }}</td>
                </tr>
              </tbody>
            </table>

            <table aria-label="Signal Status Table" style="margin-bottom: 15px;" class="hc-table">
              <thead>
                <tr>
                  <th id="col">{{ $t('helicard.acs_config') }}</th>
                  <th id="col">{{ $t('helicard.acs_custo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ checkSession(helicopter.session.acs_config) }}</td>
                  <td>{{ checkSession(helicopter.session.acs_custom) }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-else>
            <!-- Non-Mini WACS Tables -->
            <table aria-label="Signal Status Table" style="margin-bottom: 15px;" class="hc-table">
              <thead>
                <tr>
                  <th id="col"></th>
                  <th id="col">HW Part Number</th>
                  <th id="col">HW S/N</th>
                  <th id="col">SW Part Number</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ACS</td>
                  <td>{{ checkSession(helicopter.session.acs_hw_pn) }}</td>
                  <td>{{ checkSession(helicopter.session.acs_hw_sn) }}</td>
                  <td>{{ checkSession(helicopter.session.acs_sw_pn) }}</td>
                </tr>
                <tr>
                  <td>CM</td>
                  <td>{{ checkSession(helicopter.session.cm_hw_pn) }}</td>
                  <td>{{ checkSession(helicopter.session.cm_hw_sn) }}</td>
                  <td>{{ checkSession(helicopter.session.cm_sw_pn) }}</td>
                </tr>
                <tr>
                  <td>RMD</td>
                  <td>{{ checkSession(helicopter.session.rmd_hw_pn) }}</td>
                  <td>{{ checkSession(helicopter.session.rmd_hw_sn) }}</td>
                  <td>{{ checkSession(helicopter.session.rmd_sw_pn) }}</td>
                </tr>
              </tbody>
            </table>

            <table aria-label="Signal Status Table" style="margin-bottom: 15px;" class="hc-table">
              <thead>
                <tr>
                  <th colspan="2">{{ $t('helicard.location') }}</th>
                  <th id="col">Last Import</th>
                  <th id="col">Last Export</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ checkSession(helicopter.session.gps_lat) }}</td>
                  <td>{{ checkSession(helicopter.session.gps_lon) }}</td>
                  <td>{{ lastUploadFormatedDateAndTime }}</td>
                  <td>{{ lastDownloadFormatedDateAndTime }}</td>
                </tr>
              </tbody>
            </table>

            <table aria-label="Signal Status Table" style="margin-bottom: 15px;" class="hc-table">
              <thead>
                <tr>
                  <th id="col">{{ $t('helicard.acs_config') }}</th>
                  <th id="col">{{ $t('helicard.acs_custo') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ checkSession(helicopter.session.acs_config) }}</td>
                  <td>{{ checkSession(helicopter.session.acs_custom) }}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <div class="last-update">
            <i v-if="helicopter.session.last_update !== 'N/A'"> Last updated: {{  this.$moment.utc(this.helicopter.session.last_update).format('D MMM YYYY - HH:mm') }}</i>

          </div>
        </div>
      </div>

      <div v-show="localSelectedTab === 'Data Activity Overview'" class="tab-content">
        <div v-if="isLoading && isLoadingForFile" class="loader-container">
      <div class="loader"></div>
    </div>
    <div v-else>
        <!-- Session Information -->
        <h3 class="sessions-title">Last flight session exported</h3>
        <div class="session-info">
          <table class="hc-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Session Number</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ this.sessionInfo.dateTime }}</td>
                <td>{{ sessionInfo.sessionNumber }}</td>
              </tr>
            </tbody>
          </table>
          <div class="overview-button">
            <button
              class="icon-download-arrow custom-button"
              @click.stop="activateRetrieveModal()"
              :class="{'animated-download': helicopter.sending_files}"
            >
            {{ $t('helicard.button_download_data') }}

            </button>
          </div>
        </div>

        <!-- Submit Other Sessions Button -->

        <!-- Sessions Manually Submitted 
        <div class="sessions-submitted">
          <h3 class="sessions-title">Sessions Manually Submitted</h3>
          <table class="hc-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(session, index) in sessionsSubmitted" :key="index">
                <td>{{ session.name }}</td>
                <td>{{ session.date }}</td>
              </tr>
            </tbody>
          </table>
          <div class="overview-button">
            <button class="icon-upload custom-button" @click.stop="activateUploadModal()" :class="{'animated-upload': helicopter.intransit_file > 0}">
              Export to wacs
              <span v-if="helicopter.pending_files !== 0" class="pending-files">
                {{ helicopter.pending_files }}
              </span>
            </button>
          </div>
        </div> -->

        <!-- Transfer to WACS Status -->
        <div class="transfer-status">
          <h3 class="sessions-title">Last FLS imported</h3>
          <table class="hc-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>User File Name</th>
                <th>Type</th>
                <th>Target</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="transferStatus.length === 0">
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr v-else v-for="(transfer, index) in transferStatus" :key="index">
                <td>{{ transfer.formatted_upload_date }}</td>
                <td>{{ transfer.initial_file_name }}</td>
                <td>{{ transfer.file_type }}</td>
                <td>{{ transfer.target }}</td>
                <td>{{ transfer.transfer_status.charAt(0).toUpperCase() + transfer.transfer_status.slice(1) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="overview-button">
            <button v-if="isAdmin"
            class="icon-upload custom-button" 
            @click.stop="activateUploadModal()">
              {{ $t('helicard.button_upload_data') }}
            </button>
          </div>
        </div>
    </div>

      </div>
    </div>

  </div>

</template>
<style scoped>
.full-info-data{
  overflow: hidden;
}
.detailStatus{
padding-bottom: 9px;
}
.idTitle{
  font-size: 16px !important;
}

.type{
  margin-bottom: 10px !important;
}
.parent-container { 
  display: flex;
  flex-direction: column;
  gap: 10px; 
}
.tabs {
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px solid #ddd;
    margin-top: 4rem;
    margin-bottom: 2rem;

  }
  .tabs button {
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
    color: #00205b;
    transition: background-color 0.3s ease, border-bottom 0.3s ease;
    flex: 1;
  }
  .tabs button.active {
    border-bottom: 3px solid #005587;
    background-color: #f1f1f1;
  }
  .tabs button:hover {
    background-color: #e0e6ea;
  }
  .tab-content {
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    animation: fadeIn 0.3s ease-in-out;
  }

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
}

.loader {
  margin-left: 120px;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.configuration-bloc {
    margin-bottom: 15px;
    margin-top: 6rem;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

.last-update {
  font-style: italic;
  color: gray;
  margin-top: 5px;
  font-size: 0.8rem;
}

.hc-table {
  width: 100%;
  border-collapse: collapse;
}
.hc-table th {
  background-color: #00205b;
  font-weight: bold;
  padding: 8px;
  text-align: center;
}

.sessions-title {
  margin-bottom: 10px;
  font-size: 0.8em;
  font-weight: bold;
  color: #333;
}


.overview-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.aircraft-icon{
  margin-right: 80px;
}
</style>